@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* app download */
.dwn__wrapper{
    width: 1200px;
    margin: 3rem auto;
    padding: 7rem 3rem 4rem;
    }
.dwn__wrapper-section{
    display: flex;
    flex-direction: row;
    
}
.dwn__wrapper-img{
    display: block;
    width: 50%;
}
.dwn__wrapper-img img{
    width: 100%;
    border-radius: 10px;
}
.dwn__wrapper-text{
    display: flex;
    flex-direction: column;
    width: 47%;
    padding-left: 2em;
    text-align: center;
}
.wt-head{
    margin-top: 1rem;
}
.wt-head h1{
    font-size: 2.25rem;
    line-height: 3rem;
    color: #2b065e;
}
.wt-dwn-pic{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.wt-dwn-pic img{
    margin-right: 10px;
}
.wt-txt{
    margin-bottom: 1rem;
}
.wt-txt p{
    margin-bottom: 0.5rem;
    color: #54606A;
}
.wt-qrcode{
    margin-bottom: 2rem;
}
.wt-qrcode img{
    width: 150px;
}
.dwn__wrapper_right_padding{
padding-right: 9rem;
}
.question-app {
    background-color: #fff;
    width: 950px;
    min-height: 60vh;
    /* border-radius: 15px; */
    padding: 20px;
    margin: 0 auto;
    margin-top: 85px;
    margin-bottom: 85px;
    box-shadow: 0 3px 5px rgb(160 159 159 / 50%);
  }
  
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .question-count span {
    font-size: 22px;
    color: #5f5e61;
  }
  
  .question-text {
    margin-bottom: 12px;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    
    position: relative;
    padding: 10px;
    font-size: 25px;
    text-align: center;
    /* align-items: center; */
    /* margin-left: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
  .question-button {
    width: 80%;
    margin: 10px 0;
    background: #fff;
    border: 1px solid #dbdbdb;
border-radius: 3px;
font-family: Noto Sans,sans-serif;
font-size: 17.5px;
padding: 24px 25px;
text-align: left;
cursor: pointer;
outline: none;
    text-align: center;
  }
 
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }

  
  .question-button:focus {
    outline: none;
  }
  
  .question-button svg {
    margin-right: 5px;
  }

.question-previous {
    padding: 15px 21px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(148 145 145);
}

.question-previous:disabled {
    background-color: rgb(255, 255, 255);
}
.question-submit {
    padding: 15px 21px;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(63, 77, 206);
}
.question-submit {
    background-color: rgb(42, 56, 179);
}
.question-submit:disabled{
    background-color: #9e9fa0;
}
.email-input-question {
    width: 80%;
    height: 60px;
    padding: .475rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;

}
.selected {
   
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        background-color: #43d843;
        border-radius: 15px;
        /* display: flex; */
        padding: 5px;
        justify-content: flex-start;
        /* align-items: center; */
        border: 5px solid #234668;
        cursor: pointer;
        margin-bottom: 10px;
        text-align: center;
      
}

.te__card {
    position: relative;
    display: flex;
    width: 450px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 1px solid #d2d2dc; */
    border-radius: 11px;
    box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.te__card .te__card-body {
    padding: 1rem 1rem
}

.te__card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}
.te__card-title{
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
}
.te__card-title img{
    width: 69px;
}
.te__card p {
    font-size: 0.875rem;
    margin-bottom: .5rem;
    line-height: 1.5rem
}

.te__card h4 {
    line-height: .2 !important
}

.te__card .profile {
    margin-top: 16px;
    margin-left: 11px
}

.te__card .profile-pic {
    width: 58px
}

.te__card .cust-name {
    font-size: 18px
}

.te__card .cust-profession {
    font-size: 10px
}

.te__items {
    width: 90%;
    margin: 0px auto;
    margin-top: 67px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.te__bg-ds{
    background-color: #89e2e2;
    padding: 26px 0 87px;
}
.slick-slide {
    margin: 10px
}
.tes__plan-container {
    width: 90%;
    margin: 60px auto;
}
.plan-content_second{
    min-height: 175px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 10px;
}
.plan-content_second p {
    font-size: 14px;
    color: #767683;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10px;
    
}
.ser-heading {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.5;
    margin-top: 27px;
}
.ch__help-container{
    padding: 0 25px;
    margin: 60px auto;
    display: flex;
    flex-direction: row;
    width: 90%;
}
 .left-section{
    padding: 10px;
    width: 50%;
}

.left-section .help-points{
    padding: 40px;
}
.left-section .help-points p{
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 0;
    border-bottom: 1px solid #e0e0e0;
}
.right-section {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.right-section img{
    width: 70%;
    border-radius: 20px;
    
}
.selected-product-cl{
    background-color: #dedddd;
    border: 2px solid #2A265F;
}
.ques__buttons{
    width: 80%;
    display: flex;
    margin: 60px auto;
    justify-content: space-between;
}
.save-cost{
    position: absolute;
    right: 0;
    top: 0;
    background: #ce1818;
    padding: 7px;
    border-bottom-left-radius: 5px;
    color: #fff;
    font-size: 0.8em;
    font-weight: 600;
}
.seld-ques{
    background-color: #58d5c9;
}
.seld-ques:hover{
    background-color: #58d5c9!important;
}
.plan-def-toog{
    padding-top: 10px;
    display: none;
}
.tick-mark {
    position: absolute;
    /* left: 0; */
    top: 1px;
    /* display: inline-block; */
    height: 30px;
    background: white;
    color: #000;
    /* padding: 10px; */
    z-index: 1000000000;
}
.expand-icon-product {
    display: flex;
    padding: 10px;
    cursor: pointer;
}
.dep__banner{
    /* background: url(../images/services/depressionv1.jpg) no-repeat  50% 50% ; */
    background-size: cover;
    cursor: pointer;
    
}
.stress__banner{
    /* background: url(../images/services/stressv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.relation__banner{
    /* background: url(../images/services/relationshipv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.online__banner{
    /* background: url(../images/services/onlinetherapyv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.chat__banner{
    /* background: url(../images/services/chatv1.jpg) no-repeat  50% 50%; */
    background-size: cover;
    cursor: pointer;
    
}
.gen__segment-container {
    width: 85%;
    margin: 40px auto 50px;
    box-shadow: 0 4px 18px 3px #d6d3ea;
    border-radius: 9px;
    background-color: #fff;
}
.exp__help-container {
    padding: 0 25px;
    margin: 0 auto ;
    display: flex;
    width: 90%;
    flex-direction: row;
}
.pcr__card-container{
    width: 80%;
    margin: 0 auto;
}
.product__cards{
    display: flex;
  flex-wrap: wrap;
}
.p__card{
    margin: 20px;
  padding: 20px;
  width: 29%;
  min-height: 100px;
  display: grid;
  grid-template-rows: 30px 30px 30px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}
.p__card:hover{
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
.pcr__title{
    grid-row: 2/3;
    font-size: 1.4em;
    font-weight: 600;
  color: #ffffff;
  text-align: center;
}
.pcr1 {
    background: radial-gradient(#08b5c3, #297ba9);
  }
  
  .pcr2 {
    background: radial-gradient(#c56477, #921e3b);
  }
  
  .pcr3 {
    background: radial-gradient(#5783bb, #3e2880);
  }
  
  .pcr4 {
    background: radial-gradient(#3eb98d, #108075);
  }
  
  .pcr5 {
    background: radial-gradient(#ca45a6, #4d3171);
  }

  .pdm-top{
      padding-top: 40px;
  }
  .res__box p {
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.9em;
}

.res__box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px rgba(0, 0, 0, 0.4);
    padding: 30px;
    margin: 20px;  
}
.res__box h2{
    font-size: 1.3em;
}
.resolves__container {
    margin-bottom: 11rem;
}
.a__therapists_section{
    display: flex;
    flex-direction: column;
}
.la__img-circle{
    width: 240px;
    height: 240px;
    margin: 0 auto;
}
.la__img-circle img{
    width: 100%;
    border-radius: 50% !important;
}
p.la__role {
    color: #b3b3b3 !important;
    word-wrap: break-word;
    position: relative;
    margin-top: 20px;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
}
.la__name{
    font-size: 1.5rem;
    margin-top: 12px;
    margin-bottom: 0;
    text-transform: none;
    font-weight: 700;
    color: #d8a8ab !important;
    word-wrap: break-word;
    position: relative;
    text-align: center;
}
.la__bio{
    color: #5a5959;
    font-size: .9em;
    margin-top: 23px;
    margin-bottom: 0;
    font-weight: 300;
    font-style: normal;
    word-wrap: break-word;
    position: relative;
    text-align: center;
}
.area-expertise {
    margin-top: 23px;
}
.area-expertise h6{
    text-decoration: underline;
    text-align: center;
}
.area-expertise li{
    font-size:.95em ;
    color: #5a5959;
}
.spc-top{
margin-top: 40px;
}
@media (max-width: 450px) {
    .res__box {
        height: 200px;
    }
    .resolves__container {
        margin-bottom: 0!important;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .res__box {
        text-align: center;
        height: 180px;
    }
}

.cyan {
    border-top: 3px solid var(--cyan);
}
.red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
}
.green {
    border-top: 3px solid var(--green);
}
.yellow {
    border-top: 3px solid var(--yellow);
}




@media (min-width: 950px) {
    .res__row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .res__row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .res__box-down {
        position: relative;
        top: 150px;
    }
    .res__box {
        width: 26%;
     
    }
   
    
}
    @media screen and (max-width: 900px){
    .dna-wrapper {
        margin: 2rem auto;
        padding: 9rem 3rem 4rem;
    }
    .dwn__wrapper{
        width: 700px !important;
    }
    .dwn__wrapper-section{
        flex-direction: column !important;
    }
    .dwn__wrapper-img{
        width: 100% !important;
    }
    .dwn__wrapper-text{
           
        width: 100% !important;
    }
    .te__card{
        width: 300px;
    }
    .question-app{
        width: 700px;
    }
    }

  @media screen and (max-width: 500px){
    .ser-heading {
        font-size: 23px;
    }
    .dwn__wrapper_right_padding{
        padding-right: 2rem !important;
        }
        .dwn__wrapper{
            width: 360px !important;
            padding:5rem 0 0 !important;
        }
        .dwn__wrapper-text{
           
            width: 100% !important;
        }
        .question-app{
            width: 370px;
        }
        .te__items{
            flex-direction: column;
        }
        .te__card{
            width: 100%;
            margin-top: 10px;
        }
        .plan-content_second {
            min-height: -webkit-fit-content!important;
            min-height: -moz-fit-content!important;
            min-height: fit-content!important;
        }
        .question-button {
            width: 90%;
            padding: 15px 25px;
            font-size: 14px;
        }
        .question-text {
            margin-bottom: 0px;
            font-size: 18px;
        }
        .question-count {
            margin-bottom: 10px;
        }
        .dep__banner{
            /* background: url(../images/services/depression-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .stress__banner{
            /* background: url(../images/services/stress-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .relation__banner{
            /* background: url(../images/services/relationship-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .online__banner{
            /* background: url(../images/services/onlinetherapy-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .chat__banner{
            /* background: url(../images/services/chat-mobile.jpg) no-repeat 50% 50% ; */
            background-size: contain;
            margin-top: 1.2em;
        }
        .ch__help-container{
            flex-direction: column;
            width: 100%;
        }
        .exp__help-container{
            flex-direction: column;
            width: 100%;
        }
        .left-section{
            width: 90%;
        }
        .right-section{
            width: 90%;
        }
        .pcr__card-container{
            width: 100%;
        }
        .p__card{
            width: 90%!important;
            margin: 10px 20px;
            min-height: 70px;
            grid-template-rows: 2px 25px 2px;
        }
        .pcr__title{
            font-weight: 500;
        }
        .left-section .help-points{
            padding: 0!important;
        }
        .right-section img{
            width: 90%;
        }
        .care-h-menu-link .btn{
            padding: 0!important;
        }
        .save-cost{
            padding: 5px;
        }
        .program-description {
            padding: 8px!important;
        }
    }


.typeform-welcome-screen {
    position: absolute;
    bottom: 50%;
    left: 50%;
    border: 1px solid #fff;
}
.typeform-welcome-text {
    text-align: center;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    
}
.typeform-button{
    margin-top: 15px;
    padding: 10px;
    color: #fff;
    background-color: #0445AF;
    width: 80px;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    right: 17%;
    border-radius: 5px;
}
.typeform-button:hover{
    background-color: rgb(79, 36, 197);
}
.typeform-app{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.typeform_Progress{ 
        position: relative;
        transition: width .4s ease;
        margin-bottom: 1em;
        background: rgb(181, 181, 181);
        border-radius: 0;
        width: 100%;
        height: 2em;
}
.typeform_progress_bar{
        min-height: 28px;
        background-color: #91AEDC;
}
.typeform-question-bar{
   top: 20;
    position: fixed;
    left: 30%;
}
.typeform_count_bar{
    position: absolute;
    right: 5px;
    top: 0;
    font-weight: normal;
    color: #fff;
    height: 100%;
    /* font-family: @sans; */
    font-size:1.25em;
    margin: auto .5em;
    letter-spacing:.025em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.typeform-question-text{
    position: relative;
    left: 18%;
    margin-bottom: 20px;
    font-size: 20px;
    color: black;
    font-weight: bold;
    max-width: 70%;

}
.typeform-answer-section{
    position: relative;
    left: 22%;
    width: 20%!important;
    max-width: 30%;
    height: 2%;
}
.typeform-question-button   {
    width: 135%;
    margin: 4px 0;
    background: #fff;
    border: 1.5px solid #91AEDC;
    border-radius: 3px;
    font-family: Noto Sans,sans-serif;
    font-size: 17.5px;
    padding: 8px 39px 8px 18px;
    text-align: left;
    cursor: pointer;
    outline: none;
    text-align: center;
    background-color: #E5ECF7;
    color: #0445AF;
    max-width: 140%;

}
.typeform-inline-text{
    position: relative;
    left: 3%;
    right: 40%;
}
.typeform-question-count{
    position: relative;
    margin-bottom: 150px;
}

.typeform-ques__buttons {
    
    position: fixed;
     bottom: 30px;
     right: 60px;

}

.typeform-ok-button{
    position: relative;
    left:22%;
    font-weight: bolder;
    padding: 10px 25px;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    background-color: #0445AF;

}
.typeform-question-previous {
    padding: 10px 16px;
    border-radius: 5px;
    margin: 1px;
    padding: 10px 12px;
    font-size:15px;
    color: #fff;
    background-color: rgb(148 145 145);
}
.typeform-assesment-screen{
    width:100%;
    height: 100%;
}
.typeform-submit-section{
    margin-left: 222px;
    width: 600px;
}
.typeform-back-arrow{
    margin-bottom: 10px;
    color: #0445AF;
    }
    .typeform-back-arrow:hover{
        color: rgb(25, 226, 68);
    }
.typeform-submit-section h3{
    max-width: 40%;
    text-align: center;
}
.typeform-question-submit {
    position: relative;
    font-weight: bold;
    left: 13%;
    padding: 10px 25px;
    border-radius: 5px;
    margin-top: 10px;
    color: #fff;
    background-color: #0445AF;
}
.typeform-check-icon{
    position: relative;
    left:15%
}
.show_up{
    margin-top: -45px;
}
.show_case{
    padding: 5px;
    color:#215352;
    font-weight: bold;
    background-color: #fff;
    border-radius: 2px;
    text-align: center;
    margin: 10px auto;
    width: 45%;
}
.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 2rem!important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
            user-select: none;
}
.typeform-text-input{
    border:none;
    border-bottom: 1px solid blue;
    width:150%;
    font-size: 20px;
    color: cornflowerblue;
    position: relative;
    left: 0%;
}
.typeform-textarea{
    border: none ;
    border-bottom: 1px solid blue;
    width:150%;
    font-size: 20px;
    color: cornflowerblue;
    position: relative;
    left: 0%;
    padding-bottom: -30px;
    padding-top: 0px;
}
textarea.typeform-textarea:focus{
    outline: none;
}
input.typeform-text-input:focus{
   outline: none;
}
.input[type="checkbox"] {
    display: none
}


input[type="file"] {
    display: none;
}
.typeform-file-input{
    position: relative;
    left:50%;
    width: 450px;
    height: 200px;
    background-color: #B1DE00;
    border: 1px dotted blue;
    padding: 70px 50px;
    /* background:  url(../images/base/uploadimage.png) no-repeat 50%, 40%; */
    background-size: 50px 50px;
}
.typeform-file-span {
    position: relative;
    top: 100%;
    left: 30%;
    font-size: 12px;
}
.typeform-file-span1 {
    position: absolute;
    top:70% ;
    text-align: center;
    left: 30%;
}
.typeform-image{
    flex: 1 1;
    flex-wrap:wrap;
    flex-direction: row;
}
.typeform-image-input{
    flex-basis: 50%;
  margin: 20px;
   border: 1.3px solid #91AEDC;
   background: #E5ECF7;
   padding: 10px 0px 10px 60px;
}
.typeform-select-input{
    max-height: 500px;
}


.css-1s2u09g-control {
    width: 200%;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    /* border-color: hsl(0, 0%, 80%); */
    /* border-radius: 4px; */
    /* border-style: solid; */
    /* border-width: 1px; */
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    /* min-height: 38px; */
    outline: 0!important;
    position: relative;
    transition: all 100ms;
    /* box-sizing: border-box; */
    font-size: 20px;
    margin-bottom: 160px;
    border: none!important;
    border-bottom: 2px solid #91AEDC!important;

}

.css-1pahdxg-control{
width: 200%;
    }
    .css-26l3qy-menu{
        width: 200% !important;
        margin: 2px;
    }
  
    .container{
         display: flex;
         flex-direction: column;
         /* width: 2px; 
         height: 20px;
         margin: 15px auto;  */
        }
  
    
div label input {margin-right: 100px;}

.cat{
  color: #0445AF;
  background-color:#E5ECF7 ;
  border-radius: 4px;
  border: 1px solid #104068;
  overflow: hidden;
  max-width: 300px;
  margin: 2px;
  text-align: center;
  margin-left: -10px;
}
.check-icon{
   position: relative;
   left:60%
}
.cat label {
  /* float: left; */
   line-height: 3.0em;
   width: 8.0em;
   height: 3.0em;
}
.cat label span {
  text-align: center;
  padding: 3px 0;
  display: block;
}

.cat label input {
  position: absolute;
  display: none;
  color: #0445AF;
}
.cat label input + span{color: #0445AF;}
.cat input:checked + span {
    color: #0445AF;
}
.action input:checked + span{background-color: #E5ECF7;}
      
.react-datepicker-wrapper {
    width: 200px!important;
        display: inline-block;
        padding: 0;
        border: 0;
        border: none;
        outline: none!important;
        border: none !important;
        border-color: #fff;
        border-bottom: 2px solid black;
    }
    .react-datepicker__input-container{
        width: 200px!important;
        border: none;
        outline: none!important;
        border: none !important;
        border-color: #fff;
        border-bottom: 2px solid black;
    }
    .react-datepicker-wrapper:focus{
        outline: none!important;
    }  
.typeform-scale-input{
    width: 250%!important;
    position: relative;
  margin-bottom: 20px;
}
.btn-scale {
    min-width: 55px;
    width: 6%;
    height: 50px;
    margin: 2px;
    text-align: center;
    font-weight: bold;
    color: #0445AF;
    font-family: 'Lato', sans-serif;
  }
  

.btn-scale-asc-1{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}
.btn-scale-asc-2{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-3{
    border: 2px solid #91AEDC!important;
    background-color:#E5ECF7!important ;
}.btn-scale-asc-4{
    border: 2px solid #91AEDC!important;
    background-color:#E5ECF7!important ;
}.btn-scale-asc-5{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-6{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-7{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-8{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-9{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}.btn-scale-asc-10{
    background-color:#E5ECF7!important ;
    border: 2px solid #91AEDC!important;

}
  /* .btn-scale-asc-1 {
    background-color: #33FF00!important;
  }
  
  .btn-scale-asc-1:hover {
    background-color: #2CDE00!important;
  }
  
  .btn-scale-asc-2{
    background-color: #66FF00!important;
  }
  
  .btn-scale-asc-2:hover{
    background-color: #59DE00!important;
  }
  
  .btn-scale-asc-3
  {
    background-color: #99FF00!important;
  }
  
  .btn-scale-asc-3:hover {
    background-color: #85DE00!important;
  }
  
  .btn-scale-asc-4 {
    background-color: #CCFF00!important;
  }
  
  .btn-scale-asc-4:hover {
    background-color: #B1DE00!important;
  }
  
  .btn-scale-asc-5{
    background-color: #FFFF00!important;
  }
  
  .btn-scale-asc-5:hover {
    background-color: #DEDE00!important;
  }
  
  .btn-scale-asc-6{
    background-color: #FFCC00!important;
  }
  
  .btn-scale-asc-6:hover {
    background-color: #DEB100!important;
  }
  
  .btn-scale-asc-7 {
    background-color: #FF9900!important;
  }
  
  .btn-scale-asc-7:hover {
    background-color: #DE8500!important;
  }
  
  .btn-scale-asc-8 {
    background-color: #FF6600!important;
  }
  
  .btn-scale-asc-8:hover {
    background-color: #DE5900!important;
  }
  
  .btn-scale-asc-9 {
    background-color: #FF3300!important;
  }
  
  .btn-scale-asc-9:hover {
    background-color: #DE2C00!important;
  }
  
  .btn-scale-asc-10{
    background-color: #FF0000!important;
  }
  
  .btn-scale-asc-10:hover{
    background-color: #DE0000!important;
  } */
  /*   color variables */
  /*   border radius */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Oxygen, sans-serif;
    /* margin: 2rem; */
  }
  .asses_header{
      position: relative;
      text-align: center;
      margin-top: 90px;
  }
  .asses_header h1{
      padding: 10px;
      font-weight: bold;
      font-size: 45px;
  }
  .asses_innertext{
      position:relative;
      left: 20%;
      max-width: 56%;
      font-size: 18px;
      margin-bottom: 45px;
      
  }
  .asses_card__body{
      position: relative;
  }
  .asses_wrapper {
    position: relative;
    left: 15%;
    max-width: 64%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 12rem));
    grid-gap: 18rem;
    grid-gap: 3rem 15rem;
    grid-gap: 1rem 10.5rem;
    gap: 1rem 10.5rem;
    justify-content: space-between;
  }
  .asses_card {
     position: relative;
    overflow: hidden;
    padding: 0;
    box-shadow: 0 2px 20px #e1e5ee;
    border-radius: 1rem;
    width: 180%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
   
  }
  .asses_card__title_hide{
      text-align: center;
    opacity: 0;
    position: absolute;
    top: 68%;
    left: 35%;
    opacity: 0;
    font-size: 20px;
    color: #ffffff;
  }

  .asses_card__title_hide.MuiSvgIcon-root {
    fill: currentColor;
    width: 0.5em!important;
    height: 1em;
    display: inline-block;
    font-size: 0.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
            user-select: none;
}
  .asses_card:hover .asses_card__title_hide{
      opacity: 1;
  }
  .asses_card__image {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    opacity: .80;
  }
  .asses_card__title {
    position: absolute;
    color: #ffffff;
    top: 50%;
    left: 30%;
    font-size: 30px;
    font-weight: bold;
    z-index: 1000;

  }
  .asses_card__description {
    padding: 0 1rem;
  }
  .asses_card__btn {
    padding: 1rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
    border: 2px solid #d50000;
    background: transparent;
    color: #d50000;
    border-radius: 0.2rem;
    transition: background 200ms ease-in, color 200ms ease-in;
  }
  .asses_card:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  .asses_card:hover .asses_card__btn {
    background: #d50000;
    color: white;
  }
  .asses_mid{
      width:auto;
      height:520px;
      background-color: rgb(178, 210, 218);
      margin-top: 50px;
      display: flex;
      flex-direction: row;
  }
  .asses_mid_img{
        border-radius: 160px 0 0 20px;
        position: relative;
        left:32%;
        background-color: #98b5bb;
       
  }
  .asses_section_header{
      text-align: center;
      margin-bottom: 30px;
  }
  .asses_therpy_signup{
      margin-top: 350px;
      width:290px;
      height:50px;
      padding:10px;
      position: relative;
      left:10%;;
      border-radius: 10px;
      font-weight: bolder;
      background: #6ec2d3;
      color: 'white';
  }
  .welcome_asses {
    background: linear-gradient(rgb(237,203,195), rgba(238, 238, 226, 0.95))!important;
    width: auto;
    height: 460px;
    margin-top: 50px;
    display: flex;
    margin-top: 60px;
  }
  .welcome_note_asses {
    width:500px;
    position: relative;
    left: 20%;
    top: 20%;
  }
  .start_asses{
      height: 40px;
      min-width: 120px;
      padding: 10px;
      position: relative;
      top: 70%;
      left: -4%;
      background-color: rgb(40, 93, 207);
      color: #fff;
      font-weight: bold;
      border-radius: 10px;
  }
  .welcome_asses img{
      position: relative;
      left: 10%;
  }
  .gad_asses{
    background: linear-gradient(rgb(207,205,204), rgba(207,205,204,0.93))!important;
  }
  .dep_asses{
    background: linear-gradient(rgb(238,241,245), rgba(207,205,204,0.93))!important;
  }
  .audit_asses{
    background: linear-gradient(rgb(27,32,37), rgba(10, 10, 10, 0.93))!important;
  }
  .auditc_asses{
    background: linear-gradient(rgb(247,246,247), rgb(239, 243, 243))!important;
  }
  .panic_asses{
    background: linear-gradient(rgb(118,138,147), rgba(221, 230, 230, 0.93))!important;
  }
  .ptsd_asses{
    background: linear-gradient(rgb(87,93,108), rgba(221, 230, 230, 0.93))!important;
  }
  .phq_asses{
    background: linear-gradient( rgb(106,63,41), rgba(221, 230, 230, 0.93))!important;
  }
  .welcome_note_asses h2 {

      font-size: 40px;
      font-weight: bolder;
  }
  .about_img_asses{
    display: flex;
    width: 100%;
    height: 450px;
    /* background: linear-gradient(rgb(240,206,200), rgba(233, 188, 227, 0.95)); */
    background: linear-gradient(rgb(237,203,195), rgba(184, 143, 143, 0.95));


  }
  .inner_img_asses{
    position: relative;
    left: 10%;
    height:420px;
    width: 620px;
    border-radius: 0 0 50% 50%;
    background: #26325A;
    padding: 10px;
   
  }
  .inner_img_asses h2 {
    color: #fff;
    position: relative;
    top:17%;
    text-align: center;
  }
  .inner_img_asses p {
      padding: 5px 5px 8px 5px;
      position: relative;
      color: #fff;
      font-size: 17px;
      top:17%;

  }
  .about_asses{
    display: flex;
    width: auto;
    height: 400px;
  }
  .about_asses_inner{
      max-width:400px;
      position: relative;
      left: 15%;
      top: 10%;
  }
  .about_asses_inner h2{
    position: relative;
    top: 5%;
    color: rgb(7, 3, 37);
    font-size: 35px;
  }
  .about_asses_inner p {
    position: relative;
    top: 5%;
    color: rgb(7, 3, 37);
    font-size: 17px;
  }
  .about_asses_shadow{
      max-width: 440px;
      max-height: 300px;
      padding: 30px;
      position: relative;
      left:25%;
      top: 14%;
      box-shadow: 0 11px 50px -18px rgb(77 101 9)
      }
      .about_asses_shadow h2 {
        padding: 50px;
        color: #e72121;
        font-size: 40px;
        font-weight: 900;
        position: relative;
        top:5%;
      }
      .about_asses_shadow p{
        position: relative;
        bottom: 16%;
        color: #ee1c46;
        text-align: center;
      }
      .testimonial_container_asses{
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 45px 0;
        padding: 25px 0;
      }
     
      .fixed-nav-bar {
        position: fixed;
        align-items: flex-end;
        justify-content: flex-end;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 60px;
        background-color: white;
        box-shadow: 0px 0px 10px gray;
        display: flex;
        line-height: 50px;
      }
      .fixed-nav-bar-scrolled {
      position: fixed;
      z-index: 9999;
      background-color: #fff!important;
      color: #2e2bce!important;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(10, 28, 64, 0.1), 0px 4px 4px rgba(10, 28, 64, 0.1);
      transition: box-shadow 0.3s ease-in-out 0s;
  }
  .nav_asses_button{
    width: 100px;
    height: 40px;
    background-color: #2076ee;
    margin-bottom: 10px;
    margin-right: 20px;
    padding: 0px;
    line-height: 2px;
    border-radius: 10px;
    color: #ffff;
    font-weight: bold;

  }
  .nav_asses_button:hover{
    color: #fff; 
    background-color: lightseagreen;

  }
      .fixed-nav-bar 
        nav img {
            float: left !important;
      }
      
      .fixed-nav-bar .assess_brand {
        flex: 1 1;
        align-self: flex-start;
        height: 50px;
        margin-left: 10px;
      }
      
      .fixed-nav-bar  .asses_link {
        /* flex: 1;
        max-width: max-content; */
        margin-right: 40px;
        font-weight: bold;
      }
      .asses_begin_bottom{
          width: auto;
          height: 420px;
          display: flex;
          flex-direction: row;
          /* background: linear-gradient(rgb(240,206,200), rgba(233, 188, 227, 0.95)); */
          background: linear-gradient(rgb(237,203,195), rgba(238, 238, 226, 0.95));


      }
      .asses_begin_bottom_para{
        max-width: 500px;
        position: relative;
        left: 30%;
        top:15%;
        font-size: 20px;
      }
      .asses_begin_bottom_img{
          position: relative;
          left: 18%;
      }
      .begin_bottom_asses{
        position: relative;
        left: 60%;
        padding: 10px;
        min-width: 120px;
        height: 50px;
        color: #fff;
        background-color: #2f2ce4;
        font-weight: bold;
        border-radius: 10px;
        top: 25%;

      }
      .typeform_text_notice{
          position: relative;
          max-width: 400px;
          left:-50%
      }
@media (max-width:450px){
    .typeform-app{
        position: relative;
        bottom: 45px;
    }
    .typeform-ques__buttons{
        display: flex!important;
        justify-content: flex-start!important;
        left: 0px;
        bottom: 0px;
    }
    .typeform-welcome-screen {
      text-align: center;
      
    }
    .typeform-question-text{
        position: relative;
        left: 8%;
        margin-bottom: 20px;
        font-size: 15px;
        color: black;
        font-weight: bold;
        max-width: 85%;
    
    }
    .typeform-welcome-text {
        position: relative;
        text-align: center;
    }
    .typeform-button{
        padding: 10px;
        color: #fff;
        background-color: lightskyblue;
        width: 50%;
        font-size: 15px;
        position: absolute;
        /* right: -20%; */
        margin-top: 190px;
        left: -20%;
    }
    .typeform-answer-section{
        position: relative;
        left: 8%;
        min-width: 60%;
        height: 2%;
        }
    .typeform-question-button   {
        min-width: 100%;
        margin: 4px 0;
        background: #fff;
        border: 1.5px solid #91AEDC;
        border-radius: 3px;
        font-family: Noto Sans,sans-serif;
        font-size: 15.5px;
        padding: 8px 39px 8px 18px;
        text-align: left;
        cursor: pointer;
        outline: none;
        text-align: center;
        background-color: #E5ECF7;
        color: #0445AF;
    
    }
    .typeform-ok-button{
        position: relative;
        left: 8%;
    }
    .typeform-submit-section{
        margin-left:50px;
    }
    .typeform-question-submit {
        position: relative;
        font-weight: bold;
        padding: 10px 25px;
        margin-left: -25px;
        border-radius: 5px;
        margin-top: 10px;
        color: #fff;
        background-color: #0445AF;
    }
    .welcome_note_asses{
        position: relative;
        top: 0;
        left: 3%!important;
        max-width: 95%!important;
        font-size: 15px;

    }
    .fixed-nav-bar{
        min-width: 45%;
        max-width: 100%;

    }
    .fixed-nav-bar-scrolled{
        min-width: 45%;
        max-width: 100%;
    }
    .welcome_asses{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-height: 120vh!important;
        padding: 8px;
        margin-left: 2px;
        margin-right: 2px;
        text-align: center;
    }
    .welcome_asses img{
        position: relative;
        left: -16%;
        max-height: 60%;
    }
    .asses_container{
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .start_asses{
        position: relative;
        top: 1%!important;
        max-width: 20vh!important;
        left: 28%!important;
    }
    .about_asses{
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        margin-top: 20px;
    }
    .about_img_inside{
        position: relative;
        left: -15%;
    }
    .about_asses_inner{
        position: relative;
        top: 0;
        min-width: 60%;
        max-width: 100%;
        left: 0; 
        text-align: center;
        margin-left: 2px;
        margin-right: 2px;
        padding: 8px;

    }
    .about_asses_shadow{
        position: relative;
        top: 0;
        margin-top: 30px;
        left:6.5%;
        max-width: 86%;
        min-height: 40%;
    }
    .about_asses_shadow h2{
        padding: 30px;
        position: relative;
        top: 10%;
    }
    .about_asses_shadow p{
        position: relative;
        bottom: 8%;
    }
    .about_img_asses{
        position: relative;
       display: flex;
       flex-direction: column;
       min-height: 100vh;
       top: 0;
       margin-top: -30px;
    }
    .inner_img_asses{
        position: relative;
        left:0;
        max-width: 100%!important;
        min-height: 53%!important;
        border-radius: 0;
        top: 2%;
        background-color: #9ab1e2;
    }
    .inner_img_asses p{
        color: #333232;

    }
    .inner_img_asses h2 {
        color: black;
        font-weight: bold;
    }
    .about_img_inside{
        position: relative;
        top: 0;
        margin-top: 10px;
    }
    .asses_carousel_item{
        max-width: 85%!important;
    }
    .asses_container_carousel{
        position: relative;
        display: flex;
        flex-direction: column;
       top: 18%!important;
    }
    .asses_testimonial{
        max-width: 100%!important;
    }
    .asses_begin_bottom{
        position: relative;
        display: flex;
        flex-direction: column; 
        min-height: 100vh;
        max-height: 120vh;
    }
    .asses_begin_bottom_para{
        position: relative;
        top:0;
        left: 0;
        margin-left: 2px;
        margin-right: 2px;
        padding: 8px;
        min-width: 60%;
        max-width: 100%;
        text-align: center;
    }
    .asses_begin_bottom_img{
        position: relative;
        top:-10%;
        left: -8%;
        max-height: 50vh;
        max-width: 30vh;
    }
    .asses_begin_bottom_img img{
        max-height: 50vh;
        max-width: 55vh;
    }
    .begin_bottom_asses{
        position: relative;
        top: 170%;
        left: 30%;
    }
    .asses_footer{
        max-width: 100%!important;
    }
    .asses_section_header{
        max-width: 100%!important; 
    }
    .gad_button{
        position: relative;
        top: 200%;
    }
    .audit_button{
        position: relative;
        top: 200%;
    }
    .panic_button{
        position: relative;
        top: 130%;
    }
}
@media (max-width:768px) and (min-width:450px) {
    .asses_container{
        overflow-y: auto;
        max-width: 100vh!important;
        overflow-x: hidden;
    }
    .welcome_asses {
        width: 100%!important;
        position: relative;
        max-height: 36vh;
    }
    .welcome_asses img{
        position: relative;
        left: 2%;
        min-height: 89%;
        max-height:100%;
        max-width: 35%;
    }
    .welcome_note_asses{
        position: relative;
        top: 3%;
        left: 10%;
        min-width:55%!important;
    }
    .start_asses{
        position: relative;
        top:75%;
        left: -35%;
        min-width: 20vh;
    }
    .about_img_asses{
        width: 100%!important;
    }
    .about_img_inside img{
        max-height: 100%;
        max-width: 100%;
    }
    .about_asses{
        width: 100%!important; 
    }
    .about_asses_inner{
        position: relative;
        left: 2%;
        max-width: 40vh;
    }
    .about_asses_shadow{
        position: relative;
        left: 4%;
        max-width: 30vh;
    }
    .about_asses_shadow h2{
        padding: 10px;
        position: relative;
        top: 10%;
        left: 5%;
    }
   
    .about_asses_shadow p{
        text-align: center;
        font-size: 20px;
       position: relative;
       top: 10%;
       margin-bottom: 50px;
    }
    .inner_img_asses{
        position: relative;
        left: 0;
        max-width: 45vh;
        padding: 4px;
        max-height: 60vh;
    }
    .asses_container_carousel{
        width: 100%!important;
        display: flex;
        flex-direction: column;
    }
    .asses_carousel_item{
        min-width: 100%!important;
        position: relative;
    }
    .asses_begin_bottom{
        width: 100%!important;
       
    }
    .asses_begin_bottom_para{
        position: relative;
        left: 10%;
    }
    .begin_bottom_asses{
        position: relative;
        left: 35%;
    }
   .asses_begin_bottom_img{
       position: relative;
       left: 2%;
   }
    .asses_testimonial{
        width: 100%!important;
        position: relative;
        left: 1%;
    }
    .asses_begin_bottom_img img{
        max-height: 100%;
        max-width: 100%;
    }
    .asses_faq{
        width: 100%!important;
    }
    .asses_footer{
        width: 100%!important;
    }
}

/* form builder code */


.form_builder{
    width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.form_toolbar{
    width: 40vh;
    min-height: 92%;
    margin-top: 5%;
    border: 1px solid grey;
    margin-left: 20px;
    background-color: #215253;
    /* background-color: rgb(37, 36, 36); */
}
.select_field{
    text-align: center;
    color: #fff;
    border-bottom: 2px solid #fff;
    padding: 20px 10px 20px 10px;
    font-size: 20px;
    font-weight: bold;
}
.form_build_preview{
    min-width: 100vh;
    min-height: 90%;
    max-height: 92%;
    border: 1px solid black;
    margin-top: 2%;
    position: relative;
    left: 70px !important;
    overflow-y: scroll;
}
.create_form{
    margin: '0 auto';
}
.form_build_preview ul li{
    list-style: none;
}
.form_item{
    margin: 8% 5%;
    border: 1px solid black;
    padding: 5%;
    width: 85%;
    cursor: pointer;
    align-self: center;
    display: flex;
    flex-direction: row;
    background-color: #ffff;
    border-radius: 10px;
    padding-left: 15% !important;
    text-align: center;
   
    
}
.edit_form{
    position: relative;
    left: 570px !important; 
    border: 1px solid grey;
    width: 40vh;
    min-height: 90%;
    margin-top: 5%;
}
/* .form_inputs{
    display: flex;
    flex-direction: row;
    margin: 30px;
} */
/* .form_inner_question{
    display: flex;
    flex-direction: row;
    margin: 10px;
} */
.form_input_text{
    position: absolute;
    top:10%;
    left:8%;
    width: 50%;
    height: 5%;
    padding: 2px 6px;
    border-radius: 4px;
}
.prev_container{
    /* border: 1px solid grey; */
    /* width: 60vh !important; */
    cursor: pointer !important;
    /* margin-right: 30vh !important; */
}
.prev_container p input{
    cursor: pointer !important;

}
.edit_button_view{
    float: right;
}
.form_name_container{
    margin: 0 auto;
    width: 107vh;
    display: flex;
    flex-direction: column;
    background-color: #215253;
    /* background-color: rgb(37, 36, 36); */
    height: 240px;
}
.form_name_container label{
        float: left;
        margin-top: 23px;
        margin-left: 40px;
        color:#fff;
        font-weight: bold;
        font-size: 16px;

}
.inputs_form{
    display: flex;
    flex-direction: row;
}
.form_name_input{
    width: 70%;
    height: 40px;
    margin: 0 auto !important;
    margin-top: 15px !important;
    padding-left: 10px;
    border-radius: 6px;
}
.form_description_input{
    width: 70%;
    height: 70px;
    padding-top: 8px;
    padding-left: 10px;
    border-radius: 6px;
    margin: 0 auto !important;
    margin-top: -30px !important;
    margin-right: 68px !important;
}
.form_file_input{
    width: 70%;
    height: 30px;
    color:#fff;
    border-radius: 6px;
    margin: 0 auto !important;
    margin-top: -30px !important;
    margin-right: 68px !important;
    border: 1px solid #fff; 
}
.inner_input_form{
    border: none ;
    outline: none;
    border-bottom: 1px solid rgb(42, 42, 44) !important;
    margin-left: 20px;
    width: 50vh;
}
.inner_textarea_form{
    margin-left: 20px;
    width: 50vh;
    padding-top: 10px;
    padding-left: 10px;
}
.inner_input_form input:focus{
    outline: none !important;
    border: 1px solid #fff !important;

}
.form_question{
    border-bottom: 1px solid rgb(42, 42, 44) !important;
    width: 50vh;
    margin-top: 10px;
    /* float: right; */
}
.edit_section{
    float: right;
    margin-top: 10px;
    width: 40vh;
    margin-right: 25px;
}
.preview_section{
    float: left;
    margin-bottom: -20px;
    margin-top: 18px;
    width: 90vh;
}

.delete_input_form{
    display: none;
}
.prev_container :hover .delete_input_form{
   float: right !important;
   position: relative !important;
   display: inline !important; 
   left: -40vh !important;
   top:-2vh !important;
}
.edit_input_placeholder{
    width: 42vh;
    padding: 8px;
    margin-bottom: 25px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:-5.5vh;
    left:10vh;

}
.edit_input_value{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:.5vh;
    left:52vh;
}
.edit_input_value_textarea{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:-5vh;
    left:52vh; 
}
.edit_input_value_max{
    width: 42vh;
    padding: 8px;
    margin-bottom: 3px;
    border-radius: 3px;
    float: right;
    position: relative;
    top:1vh;
    left:10vh; 
}
.input_dropdown_form{
    float: right;
    /* margin-top: -4px; */
    display: 'column';
    -webkit-column-count: 2;
            column-count: 2;
    margin-left: -8px;
    position: relative;
    top:-4vh;
    left:51vh;
}
.dropdown_el{
    padding-left:4px ;
    border-radius: 2px;
}
.input_dropdown_form_radio{
    float: right;
    /* margin-top: -4px; */
    display: 'column';
    -webkit-column-count: 2;
            column-count: 2;
    margin-left: -8px;
    position: relative;
    top:-10vh;
    left:51vh;  
}
.up_row{
    position: relative;
    top:-14vh; 
}
.less_up{
    position: relative;
    top:-12vh;  
}
.range_scale{
    position: relative;
    top:-3vh;  
}
.inside_dropdown_input{
    margin-bottom: 10px;

}
.dropdown_btn_form{
    display: flex;
    flex-direction: column;
    position: relative;
    left: 22vh;
    top: -4.2vh;
}
.select_form_input{
    margin-left: 20px;
    padding: 10px;
}
.radio_input_form{
    max-width: 60vh;
    margin-left: 15px;
}
.option_check{
    margin-bottom: 8px;
}
.form_range_input{
    max-width: 60vh;
    margin-left: 15px;
}
.btn_form_range0{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(224, 226, 88);
}
.btn_form_range1{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(237, 234, 12);
}
.btn_form_range2{
    /* border: .5px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(251, 247, 5);
}
.btn_form_range3{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(135, 224, 107);
}
.btn_form_range4{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(95, 198, 21);
}
.btn_form_range5{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(50, 163, 6);
}
.btn_form_range6{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(19, 116, 37);
}
.btn_form_range7{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(233, 136, 57);
}
.btn_form_range8{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: rgb(210, 52, 52);
}
.btn_form_range9{
    /* border: 2px solid #fff; */
    width: 30px;
    height: 30px;
    background-color: red;
}
.build_form{
    width: 100vh;
    height: 40px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    background-color: #215253;
}
.final_prev_form{
    position: absolute;
    width: 100vh;
    top:10vh;
    left: 5vh;
    z-index: 999;
    overflow-y: scroll;
    background-color: #ffff;
    min-height: 50vh;
    height: 100vh;
    box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
    -webkit-box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
    -moz-box-shadow: -3px 0px 20px 15px rgba(207,192,192,0.91);
}
.save_btn_form{
    width: 10vh;
    background-color: #fff;
    color: #108075;
    font-weight: bold;
    font-size: 16px;
    border-radius: 8px;
    position: relative;
    left: 90vh;
    top:1vh;
}
/* .dis_btn{
    color:#fff !important;
    background-color: #67676b;
} */
.icons_toolbar{
    position: absolute;
    left: 32vh !important;
    color: #215253;
}
.inline_preview{
    position: relative;
    left: 5vh;
    margin-bottom: 5px;
}
.input_inline_prev{
    margin-bottom: 10px;
    margin-left: 30px;
    padding:5px 15px;
}
.answer_input_inline_prev{
    margin-left: 30px; 
}
.radio_inline_prev{
    margin-left: 12px;
    /* border: 1px solid #215253;
    padding: 5px 10px;
    background-color: #215253;
    color:#fff */
}
.inline_radio{
   margin-left: 10px;
   display: flex;
   flex-direction: row;
   
}
.date_inline_prev{
    margin-left: 20px;
    border: 1px solid black;
    padding: 5px 10px;
    width: 15vh;
}
.answer_section{
    width: 100vh;
    margin: 0 auto;
    margin-top: 10vh;
    height: auto;
    padding: 30px;
    /* margin-left: 35vh; */
    /* overflow-y: scroll; */
    border: 1px solid #215253;
    background-color: #215253;
    color:#ffff;
    padding-bottom: 10vh;
}
.answer_types{
    margin-left: 1vh;
}
.input_inline_prev_ans{
    width: 90vh;
    margin-bottom: 3vh;
    height: 40px;
    padding-left: 18px;
    margin-left: 20px;
}
.form_title{
    text-align: center;
    text-decoration: underline;
    color: #fff;
    font-weight: bold;
}
.input_inline_prev_drop{
    margin-left: 24px;
    padding: 10px;
    margin-bottom: 3vh;
}
.check_box_ans{
    display: flex;
    flex-direction: row !important;
    border: 1px solid #fff;
    margin: 1vh;
    margin-bottom: 3vh;
    background-color: #fff;
    padding-left: 7px  !important;
    padding-top: 5px !important;
    height: 45px;
}
.inline_radio_ans{
    display: flex;
    flex-direction: row !important; 
    margin-left: 25px;
    border-radius: 5px;
   
}
.input_inline_txt_prev_ans{
    padding: 10px;
    margin-bottom: 3vh; 
    height: 80px;
    width: 90vh;
    padding-left: 18px;
    margin-left: 25px;
}
.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
}
.date_inline_prev_ans{
    border:none;
    margin-left: 30px;
    margin-bottom: 3vh;
    }
.form_range_input_ans{
    /* background-color: #fff; */
    width: 85vh;
    margin-left: 0px;
    /* color: #000; */
}
.btn_grp_ans{
    padding:10px ;
    text-align: center;
}
.active_btn{
    border:3px solid #fff;
    /* color:#2076ee !important; */
    /* background-color: #fff; */
    width: 50px;
    height: 50px;
    font-size: 20px;
}
.err_msg{
    text-align: center;
    font-weight: bold;
    color:#F50157;
    margin-left: 35vh;
    position: relative;
    top:-2vh;
}
.date_pic_ans{
    padding: 6px 3px;
    font-size: 18px;
    text-align: center;
}
.submit_btn_ans{
    float: right;
    width: 15vh;
    padding: 6px;
    margin-top: 20px;
    border-radius: 5px;
    font-weight: bold;
}
.welcome_form{
    width:100vh;
    margin: 0 auto;
    margin-top: 20vh;
}
.form_wel_title{
    width: 90vh;
    text-align: center !important;
    font-size: 25px;
    margin: 0 auto;
}
.form_wel_description{
   width: 90vh; 
   margin: 0 auto;
   margin-top: 20px;

}
.wel_btn{
    border-radius: 3px;
    padding: 10px;
    position: relative;
    margin-top: 3vh;
    left: 40vh;
}
.answer_section_form{
    width: auto;
    height: 100vh;
    padding: 30px;
    background-color: #215253;
    color:#ffff;
}
.answer_types_form{
    margin-top: 20vh;
    margin-left: 10vh;
}
.ques_inx{
    font-size: 22px;
}
.input_inline__ans{
    width: 90vh;
    margin-bottom: 3vh;
    height: 40px;
    padding-left: 18px;
    margin-left: 16vh; 
}
.next_div{
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 10vh;
    right: 10vh;
    cursor: pointer;
}
.exp_less{
    border: 1px solid #fff;
    margin: 3px;
    font-size: 26px;
}
.err_msg_{
    text-align: center;
    font-weight: bold;
    color:#F50157;
    position: relative;
    top:6vh;
    left: -60vh;
}
.input_inline__drop{
    margin-left: 18vh;
    padding: 10px;
    margin-bottom: 3vh;
    width: 35vh;
    margin-top: 3vh;
}
.inline_radio__ans{
    display: flex; 
    width: 35vh;
    margin-left: 18vh;
    border-radius: 5px;
}
.check_span{
    position: relative;
    left: 25vh;
}
.date_inline__ans{
    margin-left: 18vh;
    /* border: 1px solid black; */
    padding: 5px 10px;
    width: 15vh; 
}
.input_inline_txt__ans{
    padding: 10px;
    margin-bottom: 3vh; 
    height: 80px;
    width: 90vh;
    padding-left: 18px;
    margin-left: 18vh; 
}
.form_range__ans{
    position: relative;
    left:-35vh;
}
.btnform_range0{
    width: 50px;
    height: 50px;
    background-color: rgb(224, 226, 88);
}
.btnform_range1{
    width: 50px;
    height: 50px;
    background-color: rgb(237, 234, 12); 
}
.btnform_range2{
    width: 50px;
    height: 50px;
    background-color: rgb(251, 247, 5); 
}
.btnform_range3{
    width: 50px;
    height: 50px;
    background-color: rgb(135, 224, 107); 
}
.btnform_range4{
    width: 50px;
    height: 50px;
    background-color: rgb(95, 198, 21); 
}
.btnform_range5{
    width: 50px;
    height: 50px;
    background-color: rgb(50, 163, 6); 
}
span.rep-back {
    font-size: 30px;
    padding: 5px;
}
.ls-h {
    padding-left: 20px;
}
a.ls-p {
    text-decoration: none;
    font-size: 25px;
    padding-left: 15px;
    color: #215253;
}

.btnform_range6{
    width: 50px;
    height: 50px;
    background-color: rgb(19, 116, 37); 
}
.btnform_range7{
    width: 50px;
    height: 50px;
    background-color: rgb(233, 136, 57); 
}
.btnform_range8{
    width: 50px;
    height: 50px;
    background-color: rgb(210, 52, 52);
}
.btnform_range9{
    width: 50px;
    height: 50px;
    background-color: red;
}
.typeform_progress_bar_{
height: 33px;   
background-color: #108075;
margin-top: 2px;
padding-top: 3px;
}
.submit_ans_{
    padding: 5px 10px;
    border-radius: 10px;
    color:#000;
    font-weight: bold;
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
   .form_builder{
       display: flex;
       flex-direction: column;
       width: 100vh;
       height: auto;
       overflow-y: scroll;
   }
   .form_toolbar{
       width: 70vh;
       margin: 0 auto ;
       /* margin-right: 75px !important; */
   }
   .form_item{
       width: 55vh;
       font-size: 28px;
       padding: 15px;
       margin-left: 60px;
       
   }
   .icons_toolbar{
       margin-left: 305px;
   }

   .form_build_preview {
       max-width: 40vh !important;
       left: 0!important;
       position: relative;
   }
   .form_name_container{
       max-width: 100vh;
       margin-left: -10px;
   }
   .prev_container{
       max-width: 40vh;
   }
   .preview_section{
      max-width: 40vh;
   }
   .select_field{
       font-size: 28px;
       font-weight: bold;
   }
   .form_description_input{
       margin-left: 4vh;
       position: relative;
       left: -3vh;
   }
   .form_file_input{
    position: relative;
    left: -3vh;
   }
   .save_btn_form{
    position: relative;
    left: 88vh;
    top:1vh;
}
.form_question{
    width: 45vh;
    font-size: 20px;
}
.inner_input_form{
    width: 45vh;
    font-size: 20px;
}
.inner_textarea_form{
    width: 40vh;
}
.edit_input_placeholder{
    width: 40vh;
    float: right;
    position: relative;
    top:-5.5vh;
    left:50vh;
}
.edit_input_value_textarea{
    width: 40vh;
    position: relative;
    top:-6.5vh;
    left:50vh;
}
.edit_input_value{
    position: relative;
    top:-7vh;
    left:50vh; 
    width: 40vh;
}

.input_dropdown_form{
    position: relative;
    top:-6vh;
    left: 39vh;
}
.input_dropdown_form_radio{
    position: relative;
    top:-6vh;
    left: 39vh;
}
.delete_input_form{
    display: inline;
    position: relative;
    left: 40vh;
}
.range_scale{
    position: relative;
    top:1vh;  
}
.dropdown_btn_form{
    position: relative;
    left: 20vh;
    top: -2.2vh;
}
.answer_section_form{
    width:75vh;
    height: 100vh;
    overflow-y: hidden !important;
}
.next_div{
    position: absolute;
    right:5vh;
    bottom:5vh;
}
.answer_types_form{
   margin-left: 0;
}
.input_inline__ans{
    width: 65vh;
    margin-left: 4vh;
}
.input_inline__drop{
    margin-left: 6vh;
}
.welcome_form{
    width: 50vh;
    margin: 0 auto;
    margin-top: 20vh;
    
}
.form_wel_title{
    width: 50vh;
}
.form_wel_description{
    width: 60vh;
    text-align: center;
    margin-left: -25px;
}
.wel_btn{
    left: 20vh;
}

.inline_radio__ans{
    margin-left:6vh;
}
.date_inline__ans{
    margin-left: 5vh;
}
.input_inline_txt__ans{
    margin-left: 5vh;
    width: 60vh;
}
.err_msg_{
   left: 26vh;
   top:0;
   position: relative;
}
.form_range__ans{
    position: relative;
    left: 0vh;
}
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        .answer_section_form{
            width:46vh;
            /* max-width: 50vh; */
            height: 100vh;
            overflow-y: hidden !important;
        }
        .next_div{
            position: absolute;
            right:5vh;
            bottom:5vh;
        }
        .answer_types_form{
           margin-left: 0;
        }
        .input_inline__ans{
            width: 35vh;
            margin-left: 4vh;
        }
        .input_inline__drop{
            margin-left: 6vh;
        }
        .welcome_form{
            width: 30vh;
            margin: 0 auto;
            margin-top: 20vh;
            
        }
        .form_wel_title{
            width: 30vh;
        }
        .form_wel_description{
            width: 40vh;
            text-align: center;
            margin-left: -35px;
        }
        .wel_btn{
            left: 10vh;
        }
        .input_inline__drop{
            width: 33vh;
        }
        .inline_radio__ans{
            margin-left:6vh;
            width: 30vh;
        }
        .check_box_ans{
            max-width: 30vh;
        }
        .check_span{
            margin-left: -15vh;
        }
        .date_inline__ans{
            margin-left: 5vh;
        }
        .input_inline_txt__ans{
            margin-left: 5vh;
            width: 35vh;
        }
        .form_range__ans{
            position: relative;
            left: -1vh;
            font-size: 10px;
        }
        .btnform_range0{
            width: 25px;
            height: 25px;
            background-color: rgb(224, 226, 88);
        }
        .btnform_range1{
            width: 25px;
            height: 25px;
            background-color: rgb(237, 234, 12); 
        }
        .btnform_range2{
            width: 25px;
            height: 25px;
            background-color: rgb(251, 247, 5); 
        }
        .btnform_range3{
            width: 25px;
            height: 25px;
            background-color: rgb(135, 224, 107); 
        }
        .btnform_range4{
            width: 25px;
            height: 25px;
            background-color: rgb(95, 198, 21); 
        }
        .btnform_range5{
            width: 25px;
            height: 25px;
            background-color: rgb(50, 163, 6); 
        }
        .btnform_range6{
            width: 25px;
            height: 25px;
            background-color: rgb(19, 116, 37); 
        }
        .btnform_range7{
            width: 25px;
            height: 25px;
            background-color: rgb(233, 136, 57); 
        }
        .btnform_range8{
            width: 25px;
            height: 25px;
            background-color: rgb(210, 52, 52);
        }
        .btnform_range9{
            width: 25px;
            height: 25px;
            background-color: red;
        }
        .active_btn{
            border:2px solid #fff;
            /* color:#2076ee !important; */
            /* background-color: #fff; */
            width: 30px;
            height: 30px;
            font-size: 10px;
        }
        .err_msg_{
            left: 12vh;
            top:-10px;
            position: relative;
         }
        }
.box {
    margin-top: 200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .alerts {
    margin-top: 25px;
    background-color: #fff;
    font-size: 25px;
    font-family: sans-serif;
    text-align: center;
    width: 300px;
    /* height: 100px !important; */
    padding-top: 150px !important;
    position: relative !important;
    border: 1px solid #efefda !important;
    border-radius: 2% !important;
    box-shadow: 0px 0px 3px 1px #ccc;
  }
  
  .alerts::before {
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 100%;
    inset: 20px 0px 0px 100px;
    font-size: 60px;
    line-height: 100px;
    border: 5px solid gray;
    -webkit-animation-name: reveal;
            animation-name: reveal;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  
  .alerts > .alerts-body {
    opacity: 0;
    -webkit-animation-name: reveal-message;
            animation-name: reveal-message;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  
  @-webkit-keyframes reveal-message {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes reveal-message {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .success {
    color: green;
  }
  
  .success::before {
    content: "✓";
    background-color: #eff;
    box-shadow: 0px 0px 12px 7px rgba(200, 255, 150, 0.8) inset;
    border: 5px solid green;
  }
  
  .error {
    color: red;
  }
  
  .error::before {
    content: "✗";
    background-color: #fef;
    box-shadow: 0px 0px 12px 7px rgba(255, 200, 150, 0.8) inset;
    border: 5px solid red;
  }
  
  @-webkit-keyframes reveal {
    0% {
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255, 250, 250, 0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top: 5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255, 250, 250, 0.8) inset;
    }
    50% {
      border-right: 5px solid gray;
      border-left: 5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(200, 200, 200, 0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color: gray;
      box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
    }
    100% {
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
    }
  }
  
  @keyframes reveal {
    0% {
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255, 250, 250, 0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top: 5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255, 250, 250, 0.8) inset;
    }
    50% {
      border-right: 5px solid gray;
      border-left: 5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(200, 200, 200, 0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color: gray;
      box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
    }
    100% {
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
    }
  }
  
/*Variables*/
.left-section .inner-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Sans", sans-serif;
  color: #f5f6fa;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#0C0E10, #446182);
}
.background .ground {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25vh;
  background: #0C0E10;
}
@media (max-width: 770px) {
  .background .ground {
    height: 0vh;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  width: 85%;
  height: 100vh;
  padding-bottom: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 770px) {
  .container {
    flex-direction: column;
    padding-bottom: 0vh;
  }
}

.left-section, .right-section {
  position: relative;
}

.left-section {
  width: 40%;
}
@media (max-width: 770px) {
  .left-section {
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
  }
}
@media (max-width: 770px) {
  .left-section .inner-content {
    position: relative;
    padding: 1rem 0;
  }
}

.heading {
  text-align: center;
  font-size: 9em;
  line-height: 1.3em;
  margin: 2rem 0 0.5rem 0;
  padding: 0;
  text-shadow: 0 0 1rem #fefefe;
}
@media (max-width: 770px) {
  .heading {
    font-size: 7em;
    line-height: 1.15;
    margin: 0;
  }
}

.subheading {
  text-align: center;
  max-width: 480px;
  font-size: 1.5em;
  line-height: 1.15em;
  padding: 0 1rem;
  margin: 0 auto;
  color: #fff;
}
@media (max-width: 770px) {
  .subheading {
    font-size: 1.3em;
    line-height: 1.15;
    max-width: 100%;
  }
}

.right-section {
  width: 50%;
}
@media (max-width: 770px) {
  .right-section {
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
  }
}

.svgimg {
  position: absolute;
  bottom: 0;
  padding-top: 10vh;
  padding-left: 1vh;
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 770px) {
  .svgimg {
    padding: 0;
  }
}
.svgimg .bench-legs {
  fill: #0C0E10;
}
.svgimg .top-bench, .svgimg .bottom-bench {
  stroke: #0C0E10;
  stroke-width: 1px;
  fill: #5B3E2B;
}
.svgimg .bottom-bench path:nth-child(1) {
  fill: #432d20;
}
.svgimg .lamp-details {
  fill: #202425;
}
.svgimg .lamp-accent {
  fill: #2c3133;
}
.svgimg .lamp-bottom {
  fill: linear-gradient(#202425, #0C0E10);
}
.svgimg .lamp-light {
  fill: #EFEFEF;
}

@-webkit-keyframes glow {
  0% {
    text-shadow: 0 0 1rem #fefefe;
  }
  50% {
    text-shadow: 0 0 1.85rem #ededed;
  }
  100% {
    text-shadow: 0 0 1rem #fefefe;
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 1rem #fefefe;
  }
  50% {
    text-shadow: 0 0 1.85rem #ededed;
  }
  100% {
    text-shadow: 0 0 1rem #fefefe;
  }
}
@font-face {
    font-family: Montserrat;
    src: url(/static/media/Montserrat-Regular.3cd78665.ttf);
}

body {
    margin: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    color: #0F2D5A!important;
    font-weight: 400;
    font-family: 'Montserrat', 'Roboto', Arial, sans-serif!important;
}
.button--solid:disabled{
    background-color:#be9c90!important;
    cursor: not-allowed;
  } 
a {
    text-decoration: none;
}

.splash{position:fixed;z-index:2000;background:#ffffff;color:gray;top:33%;bottom:0;left:33%;right:0;overflow:hidden;}
.splash-title{text-align:center;max-width:500px;padding:20px}
.splash-title h1{font-size:26px}
.color-line{border-radius:4px 4px 0 0}
a:hover {
    text-decoration: none!important;
}

h2.title-card {
    font-size: 2.5rem;
    line-height: 1.1;
    color: #0F2D5A;
    margin-top: 5rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.care-header {
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 38px;
    box-shadow: inherit;
    transition: box-shadow 0.3s ease-in-out 0s;
}

.care-h-scrolled {
    position: fixed;
    z-index: 2;
    background-color: #fff!important;
    color: #0F2D5A!important;
    width: 100%;
    top: 34px;
    box-shadow: 0px 4px 8px rgba(10, 28, 64, 0.1), 0px 4px 4px rgba(10, 28, 64, 0.1);
    transition: box-shadow 0.3s ease-in-out 0s;
}

.care-h-scrolled .care-h-menu-link {
    color: #0F2D5A!important;
}

.care-h-scrolled a.care-h-menu-link:hover {
    color: #4369a1!important;
}

.care-h-scrolled .active {
    border-bottom: 1.5px solid #0F2D5A!important;
}

.care-h-inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 0px auto;
}

.care-h-link {
    width: 31%;
    position: relative;
    top: 6px;
    left: 30px;
    text-decoration: none;
}

.menu-icon {
    display: none;
}

.care-h-img {
    width: 22%;
}

.care-h-menu {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0px;
}

.care-h-menu-inner {
    display: flex;
    align-items: flex-start;
    align-self: center;
}

.care-h-menu-link {
    align-self: center;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    text-transform: capitalize;
    color: #fff;
    position: relative;
    margin: 0px 25px;
    text-decoration: none;
}
.care-h-menu-link .btn{
    font-size: 17px;
    color: #fff!important;
}
.care-h-menu-link .btn:focus{
    box-shadow: none!important;
}
.care-h-scrolled .care-h-menu-link .btn{
    color: #0F2D5A!important;
}
a.care-h-menu-link:hover {
    color: #32568b!important;
}

.active {
    /* border-bottom: 1.5px solid #0F2D5A; */
}

.care-banner {
    width: 100%;
    background-color: #8EC3C1;
    min-height: 552px;
    padding-bottom: 20px;
    position: relative;
    top: 35px;
}

.care-page-center {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
}

.care-banner-text {
    padding: 0 25px 25px;
    max-width: 650px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}
.depression-banner-text {
    padding: 0 25px 25px;
    max-width: 650px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}

.care-home-text {
    padding: 0 25px 25px;
    max-width: 750px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}
.care-home-text-depression {
    padding: 0 25px 25px;
    max-width: 750px;
    background: #d1d1d1db;
    margin-top: 118px;
    float: right;
    margin-right: 45px;
    height: 300px;
}
.care-banner h1 {
    font-weight: 600;
}

.care-banner h2 {
    font-weight: 600;
}

.care-banner p {
    max-width: 557px;
    font-size: 18px;
    line-height: 32px;
    white-space: normal;
    margin-top: 0;
    font-weight: 400;
}

.care-b-large {
    font-size: 48px;
    line-height: 64px;
    color: rgb(15, 45, 90);
    letter-spacing: 0.5px;
}

.care-banner ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
}

.care-banner ul li {
    display: inline-block;
    margin-right: 17px;
}

.care-banner-button {
    outline: 0;
    /* margin-top: 32px; */
    padding-top: 15px;
    padding-bottom: 15px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(255, 108, 55);
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #fff;
    text-decoration: none;
}
.care-banner-button-depression {
    outline: 0;
    margin-top: 35px;
    padding-top: 14px;
    padding-bottom: 15px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    /* display: inline-block; */
    font-size: 16px;
    margin-right: 200px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.5px;
    float: right;
    color: rgb(255, 108, 55);
    text-decoration: none;

}

a.care-banner-button:hover {
    color: #eee;
}

.care-banner-works {
    color: #1fa5ff;
    text-decoration: none;
}

.care-banner-video {
    position: relative;
    width: 50%;
    padding: 7% 10% 3%;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
    -webkit-user-select: none;
            user-select: none;
    overflow: hidden;
}

.mind-video {
    width: 100%;
    height: 452px;
    object-fit: cover;
    border-radius: 10px;
    -webkit-animation: 1s ease-out 0s 1 normal none running eMLfYp;
            animation: 1s ease-out 0s 1 normal none running eMLfYp;
    /* background: url('../images/mind/mind-video.mp4') center center / cover no-repeat rgb(17, 17, 17); */
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 0px 16px 0px rgba(110, 110, 110, 1);
}

.mind-banner {
    background-color: #FED6A8!important;
}

.no-bg {
    background: none!important;
}

.home-banner {
    /* background: url(../images/home/home-banner.jpeg) no-repeat bottom; */
    background-size: 100%;
    min-height: 652px;
}

.care-products h1 {
    font-size: 32px;
    color: #635858;
    font-weight: 600;
}

.p-h6 {
    font-size: 18px;
    color: #635858;
    font-weight: 500;
    padding-bottom: 10px;
}

.care-products {
    padding: 0 60px;
    background-color: #7EBDBB;
    transition-delay: 1050ms;
}

.care-products-primary {
    padding: 0 60px;
    background-color: rgb(255, 255, 255);
    transition-delay: 1000ms;
}

.care-products p {
    font-size: 13px;
    color: #635858;
}

.care-products-inner {
    padding: 6em 4em;
}

.care-products-col {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
}

.care-products-sec {
    order: inherit;
    width: 45%;
    padding: 0 50px;
}

img.care-products-image1 {
    width: 100%;
    border-radius: 10px;
}

.products-text {
    order: inherit;
    width: 45%;
    margin-right: 4%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
}

.products-button {
    margin-top: 2.875em;
}

.products-button-link {
    background: #0f2d5a;
    color: #fff;
    font-size: 1em;
    line-height: 1;
    padding: .7em 1.1em;
    border-radius: 4px;
    outline: 0;
    box-shadow: none;
    margin: 0;
    cursor: pointer;
    border: 0;
    transition: box-shadow 150ms, -webkit-transform 150ms;
    transition: box-shadow 150ms, transform 150ms;
    transition: box-shadow 150ms, transform 150ms, -webkit-transform 150ms;
    box-shadow: 0px 8px 16px rgba(10, 28, 64, 0), 0px 8px 8px rgba(10, 28, 64, 0);
    -webkit-transform: none;
            transform: none;
}

a.products-button-link:hover {
    color: #eee!important;
}

.products-header {
    margin-bottom: 0.5em;
    font-size: 3rem;
    line-height: 1.1;
    color: #90a6c7;
    font-weight: 700;
}

.products-def {
    font-size: 2.3rem;
    line-height: 1.5;
    font-weight: 600;
    color: #0F2D5A;
    margin-bottom: 1em;
}

.products-subdef {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    color: #0F2D5A;
}

.care-e-button {
    font-size: 18px;
    float: right;
}

.gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
}

.care-item {
    flex: 1 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.8s ease;
}

.care-item:hover {
    flex: 7 1;
}

.section-content {
    flex: 1 1;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 24px;
    opacity: 0;
    transition: all .25s .1s ease-out;
}

.content-active {
    flex: 7 1;
    opacity: 1!important;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    color: #E74C3C;
}

.care-item-title {
    font-size: 24px;
    text-align: center;
    padding: 20px 5px;
    font-weight: 500;
    font-family: cursive;
}

.care-benefits {
    margin: 0 auto;
    max-width: 1000px;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-benefits-col {
    width: 50%;
    text-align: left;
}

.care-b-img img {
    width: 100%;
    border-radius: 13px;
}

.care-b-text {
    flex-shrink: 0;
    padding-left: 60px;
    margin-bottom: 0;
    max-width: 100%;
    color: #0F2D5A;
}

.care-b-links {
    margin-top: 30px;
    margin-left: 5%;
}

.care-b-button {
    background: #0f2d5a;
    font-size: 16px;
    margin-bottom: 24px;
    color: #eaeaea;
    padding: 12px 18px;
    border-radius: 4px;
}

.care-b-button:hover {
    color: #fff;
}

.care-b-button::after {
    content: "\203A";
    font-size: 120%;
    line-height: .75;
    display: inline-block;
    margin: 0 0 0 1em;
}

.benefit-content {
    display: flex;
    height: auto;
    margin-right: 30px;
    margin-bottom: 10px;
    align-items: center;
}

.benefit-h3 {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #75ce00;
}
.benefit-h4 {
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 20px;
    color: #0f2d5a;
}

.benefit-image {
    width: 5%;
    height: 100%;
    margin-right: 0px;
}

.benefit-image img {
    width: 100%;
}

.benefit-text {
    width: 85%;
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}

.benefit-text h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.care-getapp {
    width: 100%;
    height: 400px;
    background: #F1F5F8;
}

.care-g-section {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    flex-direction: column;
}

.g-title {
    font-size: 38px;
    line-height: 45px;
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
}

.g-subtitle {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
}

.g-appicons {
    display: flex;
    justify-content: center;
}

.g-appicons img {
    margin-right: 10px;
}

.g-app-img img {
    width: 100%;
}

.how-works {
    min-height: 850px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #b6effe;
    background-position: bottom;
}

.care-h-works {
    max-width: 650px;
    padding-bottom: 30px;
    padding-top: 60px;
}

.care-h-works-h2 {
    font-size: 38px;
    font-weight: 600;
}

.h-section {
    display: flex;
    flex-flow: row;
    padding: 20px;
}

.h-number {
    font-size: 120px;
}

.h-text {
    padding: 0 10px;
}

.h-text h3 {
    font-size: 30px;
}

.h-text p {
    font-size: 18px;
    text-align: left;
}

.h-header {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.h-works-img {
    margin-bottom: 20px;
    height: 180px;
}

.h-works-img img {
    width: 60%;
}

.care-h-button {
    padding-top: 38px;
    text-align: center;
    margin-bottom: 70px;
}

.care-footer {
    background: #242950;
    padding: 50px 45px;
}

.f-section {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1400px;
    padding: 0;
}

.care-footer-section {
    padding-bottom: 30px;
}

.care-f-header {
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    color: #565b73;
    margin-bottom: 6px;
    font-weight: 500;
    text-align: left;
    padding: 0;
    background: none!important;
}

.care-f-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.care-f-list {
    margin: 0
}

.care-f-link {
    font-size: 14px;
    font-weight: 400;
    color: #aeb0bb;
    display: block;
    padding: 4px 0;
}

.care-footer-bottom {
    background: #242950;
    margin-top: 0;
    text-align: center;
}

.care-footer-bottom ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.care-footer-bottom ul li {
    display: inline-block;
    color: #565b73;
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    padding: 0 12px;
    position: relative;
    margin: 10px 0 4px;
}

.care-footer-bottom ul li a {
    display: inline-block;
    color: #565b73;
    padding: 4px 12px;
    margin: -4px -12px;
}

.care-footer-bottom ul li::after {
    display: block;
}

.care-footer-bottom ul li:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    bottom: 4px;
    border-right: 1px solid rgba(86, 91, 115, .65);
}

.care-footer-bottom p {
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    color: #565b73;
    margin-bottom: 6px;
    font-weight: 500;
}

.scroll-heading {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 7%;
}

.scroll-subheading {
    font-size: 16px;
    line-height: 1.7;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 20px;
    color: #0f2d5abd;
}

.scroll-heading::after {
    content: "";
    width: 90%;
    display: block;
    border-bottom: 1px solid #e8e8e8;
    margin-left: 10px;
}

.care-scroll {
    padding: 45px 0 15px 0;
    background-color: #FFD3A2;
    overflow: hidden;
}

.care-scroll:hover ul {
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}

.care-scroll-section:first-of-type {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
    -webkit-animation: careMoveLeft 70s linear infinite;
            animation: careMoveLeft 70s linear infinite;
}

.care-scroll-section:nth-of-type(2) {
    justify-content: flex-end;
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    -webkit-animation: careMoveRight 70s linear infinite;
            animation: careMoveRight 70s linear infinite;
}

.care-scroll-section {
    display: flex;
    list-style-type: none;
    will-change: transform;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    margin-bottom: 40px;
}

.care-scroll-section li {
    width: 200px;
    padding-right: 50px;
    flex-shrink: 0;
}

.care-scroll-section a {
    border-radius: 5px;
    overflow: hidden;
    display: block;
    transition: all .2s ease-in-out;
    will-change: transform, box-shadow, border-color;
}

.care-scroll-section header {
    background: #fff;
    text-align: right;
    padding: 14px;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
    color: #001489;
}

.care-scroll-section main {
    padding: 14px 14px 24px;
}

.care-scroll-section .ico-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    top: -37px;
    margin-bottom: -15px;
}

.care-scroll-section ._title {
    color: #001489;
    height: 79px;
    font-size: calc(14px + 3*(100vw - 420px)/860);
    font-family: Whitney Cond SSm A, Whitney Cond SSm B, sans-serif;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.care-scroll-section ._title::after {
    content: "";
    width: 40%;
    height: 2px;
    background: #001489;
    display: block;
    margin: 8px 0;
}

.care-scroll-section footer {
    color: #001489;
    height: 32px;
    font-size: calc(10px + 1*(100vw - 420px)/860);
    font-weight: 500;
    line-height: 1.5;
}

.social-links {
    margin-top: 20px;
    display: flex;
}

.social-links a {
    position: relative;
    display: flex;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
}

.career-highlight {
    border-radius: 1px;
    background: #f6f4f4;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 0 2px;
}

.coming-soon {
    border-radius: 1px;
    background: #fff;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 0 2px;
}

.care-f-link img {
    width: 100%;
}

.care-m-img {
    padding-left: 60px;
    padding-right: 40px;
}

.care-m-img img {
    width: 100%;
}

.have-q {
    color: #aeb0bb;
}

.have-q-one {
    font-size: 26px;
    line-height: 20px;
    margin-bottom: 15px;
}

.have-q-two {
    margin-bottom: 10px;
}

.offers {
    display: flex;
    flex-flow: row;
}

.care-offers {
    /* background: #fce5f4; */
    /* border:  2px solid #ffebf8; */
    margin: 20px;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
}

.care-offers-img {
    position: relative;
    order: inherit;
    width: 36%;
    padding: 0 5px 0 40px;
}

.care-offers-text {
    position: relative;
    order: inherit;
    width: 58%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 28px;
}

.care-offers-text h2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    margin-top: 1em;
}

.care-offers-text h5 {
    color: #5e5d5f;
    font-size: .9em;
    font-weight: 500;
}

.offer-button {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgb(255, 108, 55);
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #fff!important;
    border: none;
}

.strike {
    text-decoration: line-through;
    color: #5e5d5f;
}

.free-session {
    font-size: 11px;
    color: #5e5d5f;
    font-weight: 500;
}

.care-discount {
    color: #5e5d5f;
    font-weight: 500;
}

.care-price {
    color: #000;
    font-size: 17px;
    font-weight: 500;
}

.dis-40 {
    background-color: #fce5f4;
}

.works {
    margin-bottom: 40px;
    background: #fbfbfb;
}

.works-heading {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    margin: 0!important;
    padding-top: 50px;
}

.works-care {
    /* margin-right: 4rem;
    margin-left: 4rem; */
    display: flex;
    flex-flow: row wrap;
}

.works-care-img {
    position: relative;
    order: inherit;
    width: 46%;
    padding: 0 5px 0 40px;
}

.works-care-text {
    position: relative;
    order: inherit;
    width: 48%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 28px;
}

.w-number {
    font-size: 7rem;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: 400;
}

.w-title {
    font-size: 26px;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: 400;
}

.w-description {
    font-size: 1.2rem;
    margin-bottom: 0;
    line-height: 1.7;
    font-weight: 400;
    color: #0f2d5abd;
}

.w-blue {
    color: #3ecadd;
}

.w-green {
    color: #aeec73;
}

.w-pink {
    color: #fa476f;
}

.w-voilet {
    color: #664277;
}

.w-yellow {
    color: #d3d02e;
}

.talk-container {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-flow: column;
}

.talk-box-content {
    max-width: 600px;
    text-align: center;
    ;
}

.talk-box {
    padding: 0 30px;
    border-radius: 6px;
    text-align: center;
}

.t-box-sec {
    padding: 0 0 50px;
}

.t-box-sec h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.5;
}

.t-box-sec p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 40px;
}

.talk-button {
    background-color: #fa476f;
    color: #fff;
    border-radius: 27px;
    padding: 12px 14px;
    font-size: 16px;
    line-height: 1.5;
}

a.talk-button:hover {
    color: #fa476f;
    background-color: #fff;
}

.care-meet-links {
    margin-top: 30px;
}

.care-meet {
    margin-left: 3rem;
    margin-right: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-exp-bg {
    background-color: #f9e4de;
    /* background: #f9e4de url(../images/mind/mind-exp.jpg) no-repeat bottom right; */
}

.care-exp {
    margin-left: 3rem;
    margin-right: 3rem;
    align-items: center;
    min-height: 450px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-exp-col-one {
    width: 60%;
    text-align: left;
    z-index: 1;
}

.care-exp-col-two {
    width: 40%;
    text-align: left;
}

.care-exp-text {
    flex-shrink: 0;
    padding-left: 25px;
    margin-bottom: 0;
    max-width: 100%;
    color: #0F2D5A;
}

.care-exp-links {
    margin-top: 30px;
}

.care-exp-button {
    background: #0f2d5a;
    font-size: 16px;
    margin-bottom: 24px;
    color: #eaeaea;
    padding: 12px 18px;
    border-radius: 4px;
}

.care-exp-button:hover {
    color: #fff;
}

.care-exp-button::after {
    content: "\203A";
    font-size: 120%;
    line-height: .75;
    font-family: Consolas, Monaco, monospace;
    display: inline-block;
    margin: 0 0 0 1em;
}

.care-exp-img {
    position: relative;
    padding: 20px 40px;
}

.care-exp-img img {
    width: 100%;
}

.exp-h3 {
    font-weight: 600;
    font-size: 46px;
    margin-bottom: 20px;
}

.exp-text-para {
    font-size: 2.6rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0f2d5abd;
}

.photo-one {
    position: relative;
    width: 65%;
    top: 0;
    left: 0;
    z-index: 1;
}

.photo-one img {
    width: 100%;
}

.photo-two {
    position: absolute;
    width: 30%;
    top: -4rem;
    left: 61%;
}

.photo-two img {
    width: 100%;
}

.photo-three {
    position: absolute;
    width: 42%;
    bottom: -7rem;
    right: 0;
}

.photo-three img {
    width: 100%;
}

.faq-links {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.faq-button {
    background: #0f2d5a;
    font-size: 16px;
    color: #eaeaea;
    padding: 12px 18px!important;
    border-radius: 4px;
    display: inline-block!important;
}

.meet-text {
    width: 85%;
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}

.meet-text-para {
    font-size: 1.1rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0f2d5abd;
}

.timer {
    color: #ff8f03;
}

.wh-one {
    background-color: #FFF1A2;
}

.wh-two {
    background-color: #EDFEED;
}

.wh-three {
    background-color: #5782f67e;
}

.pad-right-60 {
    padding-right: 60px;
}

.pad-left-60 {
    padding-left: 60px;
}

.pad-left-136 {
    padding-left: 136px;
}

.pad-right-136 {
    padding-right: 136px;
}

.middle-align {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-flow: column;
}

.middle-align img {
    width: 100%;
}

.care-h-menu-inner .icon {
    display: none;
}

.thanks-section {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.thanks-section h2 {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    padding-top: 10px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
}
.l_care-header{
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    box-shadow: inherit;
    transition: box-shadow 0.3s ease-in-out 0s;
}
.left-spacing{
    padding-right: 15%;
}
@media (max-width: 1024px) {
    .care-h-link {
        width: 15%;
        position: relative;
        /* top: 22px; */
        left: 15px;
        text-decoration: none;
    }
    .care-h-menu-link {
        font-size: 15px;
        margin: 0px 1.75rem;
    }
    .care-exp-bg {
        background-size: 56%;
    }
    .care-h-img {
        width: 90px;
    }
    .care-b-large {
        font-size: 24px;
        line-height: 32px;
    }
    .care-banner-text {
        max-width: 500px;
    }
    .home-banner {
        background-size: 100%;
    }
    .care-banner-video {
        padding: 10% 6% 3%;
    }
    .mind-video {
        height: 333px;
    }
    .points-container{
        width: 95%!important;
    }
    
}

@media screen and (max-width: 600px) {
    .testimonial_container{
        display: flex;
        flex-direction: column!important;
    }
    .Carousel-content{
        width: 100%!important;
    }
    .Carousel-sl{
        width: 100%!important;
    }
    .care-h-inner {
        min-height: 60px;
    }
    .care-h-menu-inner a {
        display: none;
    }
    .care-h-menu-inner a.icon {
        margin-right: 20px;
        float: right;
        display: block;
    }
    .care-products {
        padding: 0 10px;
    }
    .care-products-inner {
        padding: 2rem 2rem;
    }
    .care-products-col {
        display: flex;
        margin-bottom: 30px;
    }
    .care-products-sec {
        width: 100%;
        padding: 20px!important;
    }
    .care-products-primary {
        padding: 0 10px;
    }
    .care-benefits {
        display: inline-block;
    }
    .care-banner {
        position: relative;
        top: 40px!important;
    }
    .productline{
        margin: 140px auto 0px!important;
    }
    .product-banner {
        position: relative;
        top: 40px!important;
    }
    .care-benefits-col {
        width: 100%
    }
    .care-b-text {
        padding-left: 20px;
    }
    .g-appicons img {
        margin-right: 10px;
        width: 30%;
    }
    .care-exp {
        display: inline;
    }
    .care-exp-col-one {
        width: 100%;
    }
    .care-exp-col-two {
        width: 100%;
    }
    .g-title {
        font-size: 32px;
        width: 90%;
        padding-bottom: 10px;
        text-align: center;
    }
    h2.title-card {
        font-size: 2.3rem;
    }
    .care-home-text {
        margin-top: 95px;
    }
    .exp-h3 {
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 5px;
    }
    .care-banner p {
        line-height: 22px;
    }
    .care-header {
        background: #fff;
    }
    .works-care {
        display: flex;
    }
    .works-care-img {
        order: 1;
        padding: 0 28px!important;
        width: 100%;
    }
    .works-care-text {
        order: 2;
        width: 100%;
        margin-bottom: 20px;
    }
    .pad-left-136 {
        padding: 0;
    }
    .pad-right-136 {
        padding: 0;
    }
    .pad-left-60 {
        padding-left: 28px;
    }
    .pad-right-60 {
        padding-right: 28px;
    }
    .care-meet {
        display: inline;
    }
    .care-m-img {
        margin-top: 40px;
        padding: 30px;
    }
    .exp-text-para {
        font-size: 2rem;
    }
    .care-exp-bg {
        background-size: 40%;
    }
    .f-section {
        display: inline;
    }
    .care-f-list {
        /* text-align: center; */
    }
    .have-q {
        text-align: center;
    }
    .social-links {
        display: flex;
        justify-content: center;
    }
    .care-footer-bottom a {
        font-size: 8px;
    }
    .care-banner-text {
        margin-top: 95px;
    }
    .depression-banner-text {
        margin-top: 225px!important;
    }
    .care-page-center {
        display: inline-block;
    }
    .care-banner-video {
        display: none;
        width: 100%;
    }
    .mind-video {
        height: 432px;
    }
    .sign-middle h2 {
        width: 80%!important;
        margin-top: 20px!important;
        font-size: 20px!important;
    }
    .sign-sub {
        font-size: 14px!important;
        padding: 0 30px;
    }
    .care-form {
        padding: 40px 30px!important;
    }
    .form-white-bg{
        padding:0!important;
    }
    .mission-h2 {
        margin-top: 130px!important;
    }
    .care-products-sec {
        order: 1;
        width: 100%;
    }
    .products-text {
        order: 2;
        width: 100%;
    }
    .active {
        border-bottom: none!important;
    }
    .care-h-scrolled .active {
        border-bottom: none!important;
    }
    .team-section {
        padding: 0!important;
    }
    .thanks-section h2 {
        width: 80% !important;
    }
    .offers {
        display: inline!important;
    }
    .care-offers {
        width: 90%!important;
    }
    .care-offers-text h2 {
        font-size: 14px!important;
        color: #000;
    }
    .care-offers-text h5 {
        font-size: 12px!important;
        color: #5e5d5f;
    }
    .blog-b-image img{
        width: 100%;
    }
    .blog-detailed-section{
        padding: 0 20px;
    }
    .blog-content-section{
        display: flex;
        flex-flow:column!important;
    }
    .blog-image-section{
        width: 90%!important;
        min-height: auto!important;
    }
    .blog-title{
        font-size: 2.25rem!important;
    }
    .steps-container{
        width: 95%!important;
    }
    .steps-heading{
        font-size: 25px!important;
        margin-bottom: 10px!important;
    }
    .segment-container{
        width: 95%!important;
    }
    .therapy-benefit-container{
        width: 95%!important;
    }
    .try-session-button button{
        font-size: 12!important;
    }

    .price-color{
        margin-top: 0!important;
    }
    .pricing-wrap{
        padding: 20px!important;
    }
    .productline{
        display: flex;
        flex-flow: column!important;
    }
    .product-sidebar{
        order: 1!important;
    }
    .productline-account{
        order: 2!important;
    }
    .button--solid{
        font-size: 1.0rem!important;
    }
    .care-popup-one{
        margin: 20px!important;
    }
    .service-container {
        display: flex!important;
        flex-flow: column!important;
    }
    .service-section {
        width: 100%!important;
        display: flex;
        flex-flow: column;
        padding: 10px 30px!important;
    }
    .mini-container{
        width: 95%!important;
    }
    
}

@media screen and (max-width: 600px) {
    .notification-text{
        padding: 5px!important;
        font-size: 11px!important;
    line-height: 2.6!important;
    }
    button.book-button{
        margin-left: .3rem!important;
    }
    .care-h-menu-inner.responsive {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: hidden;
        -webkit-transform: translateY(0px) translateX(0px);
                transform: translateY(0px) translateX(0px);
        transition: -webkit-transform 250ms ease 0s;
        transition: transform 250ms ease 0s;
        transition: transform 250ms ease 0s, -webkit-transform 250ms ease 0s;
    }
    .card-wrapper{
        width: 95%!important;
    }
    .card-details{
        width: 30%!important;
    }
    .card-wrapper h3 {
        font-size: 0.8em!important;
    }
    .card-wrapper p{
        font-size: .8em!important
    }
    .menu-icon {
        display: block;
        position: relative;
        float: right;
    }
    a.icon {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 18px;
    }
    .care-h-menu-inner.responsive {
        width: 100%;
        background: #fff;
        display: block;
        flex-flow: column;
        padding: 50px;
        min-height: 100vh;
        line-height: 20px;
        border-radius: 5px;
        box-shadow: 14px 14px 40px 0 rgba(118, 126, 173, 0.08);
    }
    .care-h-menu-inner.responsive a {
        float: none;
        display: block;
        text-align: left;
        border-bottom: none;
        min-height: 45px;
    }
    .care-h-scrolled .care-h-menu-link {
        color: #0F2D5A!important;
    }
    .care-h-menu-link {
        font-size: 15px;
        color: #0F2D5A!important;
    }
    .care-h-menu-link .btn{
        font-size: 15px;
        color: #0F2D5A!important;
    }
    .care-h-scrolled .care-h-menu-link .btn{
        color: #0F2D5A!important;
    }
    .no-afraid-container {
        padding: 0 20px;
    }
    .showline{
        overflow-x: auto !important;
    }
    .card_content{
        margin-bottom: 0!important;
    }
    .left-spacing{
        padding-right: 0;
    }
}

@media (max-width: 1024px) {
    .care-banner p {
        font-size: 13px;
    }
    .mind-banner {
        background-color: #FED6A8!important;
    }
    .care-banner {
        min-height: 432px;
    }
    .product-banner {
        min-height: 402px;
    }
}

@media only screen and (min-width : 601px) and (max-width : 991px) {
    .pop-c {
        font-size: 17px!important;
        font-weight: 500;
    }
    .pop-s {
        width: 95%;
        font-size: 12px!important;
        color: #414141;
        font-weight: 400;
        line-height: 1.7;
    }
    .sign-middle h2 {
        font-size: 20px!important;
    }
    .border-r{
        border-right: none!important;
        border-bottom: 1px solid #d0cfcfe0!important;
        width: 95%!important;
        margin: auto!important;
    }
    .card-wrapper{
        width: 80%!important;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1020px!important;
    }
}

#faq {
    padding: 60px 0;
    overflow: hidden;
    background: #f8f8f8;
}

#faq #faq-list {
    padding: 0;
    list-style: none;
}

#faq #faq-list li {
    border-bottom: 1px solid #ebebeb;
}

#faq #faq-list a {
    padding: 22px 0;
    display: block;
    position: relative;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    padding-right: 20px;
}

#faq #faq-list a:focus {
    outline: none;
    text-decoration: none;
}

#faq #faq-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 20px;
}

#faq #faq-list p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #767683;

}

@media (max-width: 768px) {
    #faq #faq-list a {
        font-size: 18px;
    }
    #faq #faq-list i {
        top: 13px;
    }
}

#faq #faq-list a.collapse {
    color: #1bb1dc;
}

#faq #faq-list a.collapsed {
    color: #0F2D5A;
}

#faq #faq-list a.collapsed i::before {
    content: "\f2c7" !important;
}

@-webkit-keyframes careMoveLeft {
    100% {
        -webkit-transform: translateX(-2600px);
                transform: translateX(-2600px);
    }
}

@keyframes careMoveLeft {
    100% {
        -webkit-transform: translateX(-2600px);
                transform: translateX(-2600px);
    }
}

@-webkit-keyframes careMoveRight {
    100% {
        -webkit-transform: translateX(2600px);
                transform: translateX(2600px);
    }
}

@keyframes careMoveRight {
    100% {
        -webkit-transform: translateX(2600px);
                transform: translateX(2600px);
    }
}


/* about us */

.care-mission {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    min-height: 452px;
}

.mission-h6 {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

.mission-h2 {
    max-width: 790px;
    margin-top: 20px;
    margin-bottom: 44px;
    font-size: 68px;
    line-height: 52px;
    text-align: center;
    font-weight: 600;
}

.mission-p {
    max-width: 700px;
    margin-bottom: 20px;
    color: #05253a;
    line-height: 25px;
    text-align: center;
}

.mission-bold {
    font-size: 18px!important;
    font-weight: 400;
}

.team-header {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    padding: 30px 0;
    margin: auto;
    max-width: 798px;
    display: block;
}

.team-header-one {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    padding: 0 0 30px;
    margin: auto;
    max-width: 798px;
    display: block;
}

.team-subheader {
    text-align: center;
    font-size: 15px;
    line-height: 29px;
    margin: auto;
    max-width: 798px;
    display: block;
}

.tech-member {
    position: relative;
    padding: 16px 25px 5px;
    margin-top: 35px;
    /* background: #fff; */
    transition: 250ms;
    margin-bottom: 45px;
    border-radius: 4px;
}

.team-section {
    margin-top: 24px;
    margin-left: 0;
    margin-right: 0;
    padding: 0 50px;
}

.care-member {
    position: relative;
    padding: 16px 25px 5px;
    margin-top: 35px;
    background: #fff;
    transition: 250ms;
    margin-bottom: 45px;
    border-radius: 4px;
}

.tech-team-img {
    display: flex;
    margin-top: 6px;
    margin-bottom: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.tech-team-img img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    border-radius: 100px
}

.tech-team-name {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.tech-team-position {
    margin-bottom: 10px;
    color: #8a94a6;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.care-member .care-member-img {
    display: block;
    margin: 0 auto -50px;
    position: relative;
    top: -50px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 5px solid #fff;
    background-size: cover;
    background-repeat: no-repeat;
}

.care-member .care-member-name {
    text-transform: uppercase;
    font-size: 16px;
}

.care-member .care-member-name {
    text-transform: uppercase;
    font-size: 16px;
}

.care-member .care-member-position {
    display: block;
    color: #c6c6c6;
    padding-bottom: 12px;
    border-bottom: 1px solid #c6c6c6;
}

.care-read-more .more-text {
    display: none;
}


/* .read-more{
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
  }
  .read-more:hover{
    color: #a16304;
  }
  .read-less{
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
  }
  .read-less:hover{
    color: #a16304;
  } */

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    /* overflow-y: auto; */
}

.soverlay {
    visibility: visible;
    opacity: 1;
}

.open {
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.care-popup {
    width: 50%;
    margin: 100px auto;
    border-radius: 10px;
    padding: 30px 30px 10px;
    background: #fff;
    transition: all 5s ease-in-out;
}
.care-popup-one {
    border-radius: 10px;
    padding: 30px 30px 40px;
    background: #fff;
    transition: all 5s ease-in-out;
}

.care-popup-one h3 {
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-size: 14px;
    color: #333;
}

.care-content {
    margin-top: 28px;
}

.pop-c {
    font-size: 24px;
    font-weight: 500;
}

.pop-c span {
    color: rgb(255, 108, 55);
}

.pop-s {
    width: 95%;
    font-size: 13px;
    color: #414141;
    font-weight: 400;
    line-height: 1.7;
}

.pop-start {
    margin-top: 20px;
    max-width: 320px;
}

.pop-agreement {
    font-size: 10px;
    color: #9ca0a9;
}

.pop-button {
    margin-top: 20px;
    width: 100%;
}

.pop-start-link {
    background: rgb(255, 108, 55);
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.pop-start-link:hover {
    background: rgb(221, 95, 49);
    color: #fff;
}

.noscroll {
    overflow: hidden;
}

.care-popup .close {
    position: relative;
    top: -30px;
    right: -25px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.care-popup .close:hover {
    color: rgb(255, 108, 55);
}

.care-popup .content {
    max-height: 30%;
}
.care-popup-one .content {
    max-height: 30%;
}
.diff-amount{
    font-size: 18px;
    line-height: 27px;
    padding: 20px 99px;
}

@media screen and (max-width: 700px) {
    .care-popup {
        width: 90%;
        /* display: none; */
        margin: 50px auto!important;
    }
    .diff-amount{
        padding: 0!important;
    }
}
@media screen and (max-width: 900px) {
   
    .diff-amount{
        padding: 0!important;
    }
}


/* signup */

.s-bg {
    background-color: #FED6A8!important;
}

.sign-middle {
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; */
    margin-top: 10px;
}

.sign-middle h2 {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    padding-top: 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
}

.caption {
    text-align: center;
    padding-bottom: 30px;
}

.sign-sub {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 25px;
}

.care-form {
    background: #f1f1f1;
    border: 1px solid #e0e0df;
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    box-shadow: 0 1px 4px #dfe1e5;
}

.form-group {
    width: 100%;
}

input.form-control {
    height: 40px;
    margin: 14px;
    border-radius: 2px;
    border: 2px solid #fafbfc;
    background: #fafbfc;
    box-shadow: 0 1px 4px #9e9fa0;
}

input.form-control:hover {
    border-radius: 3.01px;
    border: 2px solid #dfe1e5;
    background: #dfe1e5;
}

input.form-control:focus {
    outline: none;
    border-width: 1px;
    border-color: #4C9AFF;
    background: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.signup-button {
    background: rgb(255, 108, 55);
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    border: none!important;
}

.signup-button:hover {
    background: rgb(221, 95, 49);
    color: #fff;
}

.back-button {
    border-radius: 1px;
    background: #fff;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 5px;
}


/* readmore */

.showmore {
    font-size: 1.1em;
    margin-top: 1.5em;
}

.showmore .more,
.showmore.show .dots {
    display: none
}

.showmore.show .more {
    display: inline
}

.showmore a {
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
}

.showmore a:hover {
    color: #a16304;
}

.not-strike {
    text-decoration: line-through;
}

.notification-bar {
    position: fixed;
    width: 100%;
    top: 0;
    margin: 0;
    z-index: 2;
}

.notification-text {
    background-color: #0f2d5a;
    padding: 10px;
    color: #6fd506;
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    font-family: "Roboto",sans-serif;
    -webkit-animation: initiate .35s ease;
    animation: initiate .35s ease;
}
.not-underline{
        text-decoration: underline !important;

}
.notification-bar input {
    display: none;
}

.notification-bar label {
    cursor: pointer;
    color: #fff;
    position: absolute;
    z-index: 5;
    display: inline-block;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.notification-bar label[for=hide] {
    right: 15px;
    top: 11px;
    width: 24px;
    height: 24px;
    -webkit-animation: initiate 350ms ease;
    animation: initiate 350ms ease;
}


/* Function */

.notification-bar input[value=show]:checked~label[for=show],
.notification-bar input[value=hide]:checked~label[for=hide],
.notification-bar input[value=hide]:checked~.notification-text {
    transition: ease 350ms;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.notification-bar input[value=hide]:checked~label[for=show],
.notification-bar input[value=show]:checked~label[for=hide],
.notification-bar input[value=show]:checked~.notification-text {
    transition: ease 350ms;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}
/* terms */
.care-terms{
    margin-top:150px;
    margin-bottom: 50px;
}
.care-terms a{
    color:#3ecadd;
    text-decoration: none!important;
}
.care-terms p {
    color: #565656;
    text-align: justify;
    font-size: 13px;
    line-height: 22px;
}
.care-terms strong{
    font-weight: 600;
}
.terms-header{
    letter-spacing: -0.5px;
    color: #0d2366;
    margin-bottom: 6px;
    text-align: center;
    font-size: 36px;
    line-height: 48px;
    font-weight: 600;
}
.care-terms h1::after{
    content: '';
    display: block;
    width: 48px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}
.care-terms h3::after{
    content: '';
    display: block;
    width: 24px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
}
.terms-sub-header{
    letter-spacing: .5px;
    color: #0d2366;
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 32px;
}
.terms-sub-hints{
    padding: 0 20px;
}
/* blog */
.blog-content{
    width: 100%;
    margin-top:100px;
    padding-top: 60px;
    padding-bottom: 60px;
}
.blog-content-section{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}
.blog-image-section{
    width: 60%;
    height: auto;
    flex: 1 1 auto;
    min-height: 380px;
}
.blog-image-section img{
    width: 100%;
}
.blog-body-section{
    margin-top: 20px;
    padding: 0 0 0 2rem;
    flex-basis: 34.33333%;
    justify-content: center;

}
.blog-body-section a{
    color: #000;
    text-decoration: none;
}
.blog-body-section a:hover{
    color: #000;
    text-decoration: none;
}
.blog-title{
    font-size: 3.25rem;
    font-weight: 600;
    line-height: 1.15em;
    margin: 0 0 .4em;
}
.blog-story{
    font-size: 1.75rem;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 6em;
    margin-bottom: 20px;
}
.b-user-section{
    display: flex;
    flex-flow: row;
    flex:1 1 auto;
}
.user-image img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.user-profile{
    padding: 0 10px;
    display: flex;
    flex-flow: column;
}
.user-profile span{
    font-size: 1.25rem;
    color: #000;
}
.blog-detailed-section{
    max-width: 740px;
}
.blog-detailed-section ul{
    list-style-type: none;
}
.blog-detailed-section ul li{
    padding: 10px 0;
    color: #6c757d;
    font-size: 17px;
}
.blog-detailed-section h2{
    color: #6c757d;
    text-align: center;
}
.bd-top{
    padding-bottom: 20px;
    border-bottom: 1px solid #6c757d;
}
.bd-top h1{
    color: #111;
    margin-bottom: 20px;
    font-size: 3.65rem;
    font-weight: 700;
}
.blog-detailed-section p{
    color: #6c757d;
    margin-bottom: 20px;
    font-size: 1.75rem;
    line-height: 1.75;
    text-align: justify;
}
.no-afraid-container {
    margin: 60px auto;
    max-width: 770px;
}
.no-afraid-container p{
    color: #6c757d;
    margin-bottom: 20px;
    font-size: 1.75rem;
    line-height: 1.75;
    text-align: justify;
}
.bd-bottom{
    display: flex;
    flex-flow: row;
    padding: 30px 0;
}
.share-media{
    display: flex;
    flex-flow: row;
    align-items: center;
}
.share-media a{
    padding: 0 5px;
}
.share-media img{
    width: 25px;
}
.bd-content-section{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center
}
.blog-b-image {
    margin-bottom: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.blog-b-image img{
    max-height: 480px;
    max-width: 820px;
}

.blog-thumbnail-section{
    margin: 20px auto;
    display: flex;
    flex-flow: column;
}
.blog-th-image{
    height: 200px;
    overflow: hidden;
}
.blog-th-image img{
    width: 100%;
    height: 100%;
}
.blog-th-title{
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.15em;
    margin: 0.3em 0 .4em;
    overflow: hidden;
    max-height: 4.5em;
    /* text-align: justify; */
}
a.blog-black{
    color: #000;
    text-decoration: none;
}
a.blog-black:hover{
    color: #000;
    text-decoration: none;
}
.blog-th-story {
    font-size: 1.75rem;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 6em;
    margin-bottom: 20px;
}
/* Account */
.a-bg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    background: #fff;
    width: 100%;
    height: 65px;
}
.showline{
    position: fixed;
    top: 63px;
    left: 0;
    z-index: 15;
    border-top: 1px solid #fafafa;
    box-shadow: 0px 4px 4px rgba(19,20,22,0.02);
    background: #fff;
    width: 100%;
    height: 55px;
}
.showline-list{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: #c1c1c1;
    padding: 0;
    height: 55px;
    width: -webkit-max-content;
    width: max-content;
}
.showline-list span{
    padding: 0 10px;
}
.list-active{
    color: #0F2D5A;
}
.list-active::after{
    content: '';
    display: block;
    width: 95px;
    height: 3px;
    background: #49dab5;
    margin-top: 4px;
}
.productline{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 140px auto;
    padding: 0 24px;
    width: 100%;
    /* max-width: 975px; */
}
.productline-account{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    /* max-width: 510px; */
    /* margin-left: 55%; */
}
.account-header{
    position: relative;
    margin-bottom: 10px;
    font-weight: 400;
    max-width: 710px;
    text-align: center;
}
.account-description{
    line-height: 24px;
    font-size: 14px;
}
.product-sidebar{
    flex-shrink: 0;
    width: 100%;
    max-width: 325px;
}
.pricing-wrap{
    display: flex;
    flex-flow: column;
    padding: 120px 20px 20px;
}
.pricing-wrap .title{
    margin: 0 0 15px;
    border-bottom: 1px solid #fafafa;
    padding: 0 0 10px;
    font-weight: 400;
}
.pricing-product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pricing-product{
    margin: 17px 0;
    padding-bottom: 17px;
    border-bottom: 1px solid #dee0e3;
}
.p-product-content{
    margin: 0 0 10px;
    font-size: 16px;
}
.p-product-image{
    flex-shrink: 0;
    width: 100px;
}
.p-product-image img{
    width: 100%;
}
.p-product-price p{
    text-align: right;
}
.pricing-total{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* checkout-form-starts */
.form-wrapper {
    /* width: 440px; */
    margin: auto;
}
.contact-form-wrapper, .email-only-form-wrapper, .subscribe-form-wrapper {
    position: relative;
    overflow: hidden;
}
.form-white-bg {
    padding: .9375rem;
    border-radius: 5px;
    background-color: #fff;
}
.form-wrapper h3 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.25;
    font-size: 2.25rem;
}
.form-wrapper fieldset {
    margin: 0;
    padding: 0;
}
.name-field:first-child {
    margin-left: 0;
}
.name-field:last-child{
    margin-left: 5px;
}
.name-field-wrapper{
width: 100%;
}
.name-field {
    width: 49.1%;
    display: inline-block;
}
.form-field {
    font-size: 0;
    position: relative;
    border-radius: 3px;
    border: solid 1px #797979;
    clear: both;
    will-change: margin-bottom;
    margin-bottom: 20px;
    transition: margin-bottom .15s ease;
}
.form-field [class^=icon-] {
    top: 18px;
    left: 12px;
    color: #ccc;
    font-size: 1.5rem;
}
.form-field input, .form-field select, .form-field textarea {
    width: 100%;
    padding: 20px 10px 15px 20px;
    color: #4d4d4d;
    font-size: 1.5rem;
    font-family: National-Book,Helvetica,sans-serif;
    transition: top .2s ease,font-size .2s ease,padding .2s ease;
    border: none;
    border-radius: 2px;
    outline: 0;
}
.form-field input:focus~.form-placeholder, .form-field input~.form-placeholder.placeholder-fix, .form-field select:focus~.form-placeholder, .form-field select~.form-placeholder.placeholder-fix, .form-field textarea:focus~.form-placeholder, .form-field textarea~.form-placeholder.placeholder-fix {
    top: 10px;
    font-size: .6875rem;
    padding: 0 10px 20px 46px;
}

.form-field .form-placeholder, .form-field [class^=icon-] {
    position: absolute;
}
.form-placeholder {
    left: 0;
    top: 18px;
    color: #616060;
    font-weight: 500;
    pointer-events: none;
    transition: top .2s ease,font-size .2s ease,padding .2s ease;
    font-size: 13px;
    padding: 0px 10px 10px 20px;
}
.error-wrapper {
    float: left;
    text-align: left;
    padding: 5px 0;
    margin-left: 45px;
    opacity: 0;
    transition: opacity .1s ease .1s;
}
.hide {
    display: none!important;
}
.field-dropdown .icon-arrow-down {
    right: 20px;
    left: auto;
    font-size: .8rem;
    color: #ccc;
    top: 45%;
}
.button--solid {
    background: #0F2D5A;
}
.button--block {
    width: 100%;
}
.button--solid {
    color: #fff!important;
    padding: 10px 30px;
    font-size: 1.1rem;
    vertical-align: bottom;
    min-width: 160px;
}
.button {
    font-family: Sailec-Bold,Helvetica,sans-serif;
    cursor: pointer;
    border: none;
    outline: 0;
    border-radius: 3px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    transition: background-color .15s ease-in;
    white-space: normal;
}
.signup-terms.align-center {
    margin: 1rem 0;
}
 input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
[class*=' icon-'], [class^=icon-] {

    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contact-form-wrapper h2, .contact-form-wrapper h3, .contact-form-wrapper p, .email-only-form-wrapper h2, .email-only-form-wrapper h3, .email-only-form-wrapper p, .subscribe-form-wrapper h2, .subscribe-form-wrapper h3, .subscribe-form-wrapper p {
    transition: opacity 350ms ease,-webkit-transform 350ms ease;
    transition: opacity 350ms ease,transform 350ms ease;
    transition: opacity 350ms ease,transform 350ms ease,-webkit-transform 350ms ease;
}
label {
    display: block;
    margin: 0 0 .41666667rem 0;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    /* text-transform: Capitalize !important; */
}
:focus {
    outline: -webkit-focus-ring-color auto 1px;
}
/* checkout form end */
.care404-img{
    display: flex;
    flex-flow: column;
    max-width: 786px;
    align-items: center;
    margin: 60px auto;
    padding-top: 130px;
}
.care404-img img{
    width: 100%;
}
.care404-img h2{
    font-size: 24px;
    line-height: 1.7;
    text-align: center;
}
.go-top{
    position: fixed;
    bottom: 100px;
    right: 4%;
    z-index: 5;
    color: #000;
    visibility: hidden;
    opacity: 0;
}
.t-show{
    visibility: visible;
    opacity: 1;
}
.go-top-vertical{
    color: #000;
    font-size: 2rem;
    letter-spacing: .125rem;
    margin: 15px 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
}
.return-section{
    margin-top: 40px;
    margin-bottom: 40px;
}
.return-align{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.return-section a{
    display: inline-block;
    padding: 11px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #0F2D5A;
    line-height: 20px;
    border-style: none;
    background-color: #fafbff;
}
.return-section a:hover{
    background-color: #b4b4b4;
}

/* products */
.product-banner {
    width: 100%;
    min-height: 500px;
    padding-bottom: 20px;
    position: relative;
    top: 35px;
}
.product-banner p{
    font-size: 17px;
    line-height: 1.7;
}
.steps-container{
    width: 70%;
    margin: 0 auto 120px;
    padding-top: 35px;
}
.steps-heading{
    text-align: center;
    font-size: 35px;
    margin-bottom: 40px;
    font-weight: 300;
}
.steps-content{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.steps-image{
    padding: 20px;
}
.steps-image img{
    width: 100%;
    height: 250px;
    border-radius: 30%;
}
.steps-text{
    display: flex;
    flex-flow: row;
    padding: 0 20px;
    align-items: center;
}
.s-number{
    font-size: 40px;
}
.s-text{
    font-size: 18px;
    padding: 0 10px;
    color: #767683;
}
.segment-container{
    width: 85%;
    margin: 0 auto 120px;
    box-shadow: 0 4px 18px 3px #D6D3EA;
    border-radius: 9px;
    background-color: #fff;
}
.con-section{
    padding: 5px 35px 30px;
}
.border-r{
    border-right: 1px solid #d0cfcfe0;
}
.con-section h1{
    font-size: 20px;
    font-weight: 600;
    line-height: 40px
}
.con-section p{
    color: #767683;
    font-size: 14px;
}
.therapy-benefit-container{
    width: 40%;
    margin: 0 auto 40px;
    padding-top: 30px;
}
.therapy-benefit-container h2{
    font-size: 26px;
    font-weight: 500;
    text-align: center;
}
.therapy-benefit-section{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 25px;
    border-radius: 10px;
}
.therapy-b-image{
    width: 75px;
    height: 75px;
    padding: 10px;
}
.therapy-b-image img{
    width:100%;
    border-radius: 50%;
}
.therapy-b-content{
    color: #767683;
    font-size: 16px;
    font-weight: 600;
}
.plan-container{
    width: 75%;
    margin: 0 auto 90px;
}
.plan-sh{
    box-shadow: 0 5px 14px 0 #a9a6a6d4;
    border-radius: 7px;
}
.plan-section{
    display: flex;
    flex-flow:column ;
    justify-content: center;
    min-height: 580px;
}
.recommend-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
.recommend-label{
    border-radius: 2px;
    padding: 1.25rem 1.5rem;
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    background: #f99f05;
    color: #0F2D5A;
}
.recommend-box{
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}
.plan-content{
    margin: 20px 35px;
}
.plan-content h1{
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #c3c2c2;
    color: #f99f05;
    padding-bottom: 10px;
    padding-top: 10px;

}
.plan-content p{
    font-size: 14px;
    color: #767683;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10px;

}
.price-strike{
    font-size: 19px;
    text-decoration: line-through;

}
.price-original{
    font-size: 44px;
    line-height: 42px;
    font-weight: 500;
    color: #f99f05;
}
.price-discount{
    font-size: 16px;
    font-weight: 500;
}
.price-section{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px 20px;
}
.price-section span{
    margin-bottom: 10px;
}
.price-grab {
    font-weight: 600;
    font-size: 1em;
    text-align: center;
}
button.price-start{
    background: #3e3c86;
    color: #fff!important;
    font-weight: 600;
    font-size: 14px;
    /* padding: 11px 20px; */
    text-align: center;
    /* height: 42px; */
    border-radius: 5PX;
    border: none!important;
    text-transform: uppercase;
    /* width: 190px;
    line-height: 42px; */
    padding: 11px 7px;
}
button.price-start:hover{
    color: #fff!important;
}
.price-button{
    padding: 20px 0 0;
}
.m-bottom{
    margin-bottom: 10px;
}
.price-color{
    background-color: #0F2D5A;
    color: #fff;
    border-radius: 5px;
}
.price-color p{
    color:#fff!important;
}
span.price-call {
    text-align: center;
    padding: 0 10px;
}
.price-h{
    color: #055bf9;
    text-align: center;
}
.plan-sh:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.try-session{
    display: flex;
    flex-flow: column;
    width: 75%;
    margin: 20px auto;
}
.try-session-content{
    font-size: 26px;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
}
.try-session-content strong{
    font-weight: 700;
}
.try-session-button{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.try-session-button button{
    background: #3e3c86;
    color: #fff!important;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    height: 42px;
    padding: 0px 11px;
    border-radius: 5PX;
    border: none!important;
    text-transform: uppercase;
    line-height: 42px;
}
.try-session-button button:hover{
    color: #fff!important;
}
.future-sessions-p{
    margin-top: 10px;
    text-align: center;
    color: #0F2D5A;
    font-size: 18px;
}
.future-sessions-span{
    text-align: center;
    color: rgb(255, 108, 55);
    font-size: 12px;
    display: block;
}
ul.unordered {
    list-style-type: disc;
}
.blog-sha {
    box-shadow: 0 4px 18px 3px #D6D3EA;
    padding: 10px;
}
/* services */
h2.service-title-card {
    font-size: 3.2rem;
    line-height: 1.1;
    color: #90a6c7;
    margin-top: 5rem;
}
.service-container{
    display:flex ;
    flex-flow:row;
    align-items: center;
    background: aliceblue;
    border-radius: 50px;
    margin-bottom: 30px;
}
.service-section{
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 30px;
}
.service-body{
    display:flex ;
    flex-flow:column;
    margin: auto;
}
.careme-why {
    min-height: 120px;
}
.sr-body{
    display: flex;
    flex-flow: row;
}
.sr-body-content{
    padding: 0 40px;
    font-weight: 500;
}
.sr-number{
    font-size: 45px;
    color: rgb(248, 8, 8);
}
.sr-c{
    font-size: 45px;
    color: rgb(248, 8, 8);

}
.sr-g{
    font-size: 45px;
    color: rgb(8, 248, 48);

}
.sr-number-green{
    font-size: 21px;
    color: rgb(8, 248, 48);
}
.sr-text{
    font-size: 18px;
}
.sr-middle-body {
    text-align: center;
    padding: 20px 0;
}
.service-mini-b{
    margin: auto;
    text-align: center;
    padding: 20px;
}
.points-container{
    width: 85%;
    margin-bottom: 60px;
}
.points-container p{
    font-size: 16px;
    line-height: 1.7;
    color: #0f2d5abd;
    margin-top: 30px;
}
.points-section{
    box-shadow: 0 2px 16px 0 #D3D5DA;
    border-radius: 6px;
    padding: 8px;
    width: 50%;
    margin: 15px;
}
.points-area{
    display: flex;
    flex-flow: row;
    margin: auto;
    padding-bottom: 20px;
}
.points-area ul{
    margin: auto;
}
.points-area ul li{
    line-height: 2.1;
}
.points-section h2 {
    font-size: 22px;
    padding: 0px 45px 15px;
}
.service-h3 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 20px;
}
.service-help {
    display: flex;
    height: auto;
    margin: auto;
    margin-right: 30px;
    margin-bottom: 10px;
    align-items: center;
}
.service-text{
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}
.service-text h4{
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.therapy-help {
    line-height: 1.5;
    margin-left: 7%;
}
.service-text-help{
        height: 100%;
        margin-left: 8px;
        padding-left: 0px;
}
.service-text-help h4{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}
.margin-tp{
    margin-top: 30px;
}
.mini-container{
    width: 75%;
    margin: 20px auto;
    padding: 40px;
    min-height: 170px;
}
.mini-section{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.mini-section p{
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    text-align: center;
}
.section-left{
    align-items: baseline!important;
}
/* testimonials */

.tes-section{
    max-width: 390px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
}
.tes-content{
    background-color: #fafafa;
    padding: 12px 16px;
    margin: 0 0 10px;
    border-radius: 2px;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fafafa;
}
.tes-content p{
    font-size: .85em;
}
.tes-date {
    font-size: 11px;
    color: #33333382;
}
.tes-container{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.tes-container h1::after{
    content: '';
    display: block;
    width: 48px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}
.blog-grow{
    display: flex;
    flex-flow: wrap;
}

button.book-button {
    padding: 0 6px;
    border-radius: 4px;
    background-color: #ff6c37;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #fff!important;
    margin-left: 1rem;
    border: none;
}
.clinical{
    /* background-image: url('../images/mind/Psychiatrist.jpg'); */
    background-size: 100%;
}
.consultation{
    /* background-image: url('../images/mind/therapist.jpg'); */
    background-size: 100%;
}
button{
    border: none;
}
.program-banner{
    /* background-image: url('../images/products/relationship-counselling.jpg'); */
    background-repeat: round;
}
.banner-depression{
    /* background-image: url('../images/home/Depression+pic.jfif'); */
    /* background-size: 100%; */
   
}
.banner-bac{
    /* background-image: url('../images/home/mind-background.jpg'); */
    background-size: 100%;
}
.banner-bac-home{
    /* background-image: url('../images/home/mind-banner.jpeg'); */
    
}
.works-bac{
    /* background-image: url('../images/home/how-it-works.jpg'); */
}
.PhoneInput{
    width: 100%;
}
.PhoneInputInput{
    height: 40px;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 2px;
    border: 2px solid #fafbfc;
    background: #fafbfc;
    box-shadow: 0 1px 4px #9e9fa0;
}
.pop-otp {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 2.5rem;
}
.pop-otp button{
    margin-top: 1.5rem;
    background-color: transparent;
}
.otp-section{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.otp-section input{
    width: 42px!important;
    height: 42px!important;
    margin: 5px;
}
.otp-section button{
    margin-top: 1.5rem;
    padding: 7px 50px;
    background-color: #ec9004;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}
.otpClose{
    text-align: right;
    padding: 1rem;
}
/* Inital Animation */

@-webkit-keyframes initiate {
    0% {
        -webkit-transform: translateY(-100%);
    }
    50% {
        -webkit-transform: translateY(-50%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

@keyframes initiate {
    0% {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
    }
    50% {
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }
    100% {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    }
}

.product_card{
    margin-left: 12%;
    margin-top: 5%;
}
.card-wrapper {
    /* background-color: #fff; */
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    max-height: 120px;
    overflow: hidden;
    display: flex;
    width: 50%;
    margin-top: 10px;
    transition: max-height 500ms ease-in-out;  
    cursor: pointer;
    /* -webkit-box-shadow: 1px 0px 15px 3px #545759; 
    box-shadow: 1px 0px 15px 3px #545759; */
    
  }
  /* .card-wrapper:hover {
    box-shadow: 0 0 8px #d3d3d3;    
    cursor: pointer;
    max-height: 700px;
  } */
  .card_content a {
    display: contents;
    width: 100%;
  }
  .card-wrapper-wrap {
    box-shadow: 0 0 8px #d3d3d3;    
    cursor: pointer;
    min-height: 400px;
    padding: 2rem;

    margin-left: 160px;
    max-width: 65%;  
    margin-top: 30%;

    text-align: center;

  }
  .card-wrapper h3 {
	opacity: 1;
    margin: 0;
    font-size: 1.3em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card-wrapper p {
	letter-spacing: 1px;
	margin: 5px 0;
    opacity: 0.6;
}
  .card-details{
    background-color: #2A265F;
	color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
	width: 25%;
    position: relative;
  }
  .reveal-details {
    font-weight: 300;
  }
  .program-description{
    padding: 18px;
	position: relative;
	width: 100%;
  }
  
  /* // .reveal-details {
  //   visibility: hidden;
  //   overflow: hidden;
  //   max-height: 0;
  //   transition: 200ms ease-in-out;
  // }
   */
  
  .card-height-animate {
    height: 300px; 
  }

  .card_content{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      width:100%;
  }

.language-select{
    position: absolute;
    right: 15px;
    z-index: 100;
}  
 
.language-select .ui.fluid.dropdown {
min-width: 200px !important;

}

.language-select .ui.selection.dropdown {
    min-height: 0 !important;
    padding: 8px 7px !important;
    margin-top: 3px !important;
}

.carousel-text {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 120% !important;
    color: #2B8FFF !important;
    margin-bottom: 20px !important;
}

.carousel_view{
    min-width: 94%;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 26%);
    border-radius: 50px 50px 50px 0px;
    margin: 35px ;
    min-height: 410px;
    background-color: #fff;
    padding: 30px;
    position: relative;
    left:05%
}

.carousel-text{
    padding: 0 10px;
}   
.carousel_item p {
    font-size: 14px;
    width: 100%;
    color: #00406B;
}
.carousel_footer {
    display: flex;
    font-weight: 800 !important;
    font-size: 12px;
    align-items: center;
    line-height: 140%;
    position: absolute;
    bottom: 0.3;
    max-width: 80%;
    color: #00406b;
}
.testimonial-icon{
    /* margin-bottom: 45px; */
    text-align: center;
    font-size: 50px;
}
.testimonial_container{
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 45px 0;
    padding: 25px 0;
    background: #2382eff2;
}
.Carousel-content{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.Carousel-sl{
    width: 65%;
}

.icon-a{
color: #FECD51;
}
.carousel_star {
    margin-bottom: 10px;
}

.contact_form{
         position: relative;
        top: 0;
        left: 90%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        min-width: 550px;
        /* max-width: 500px; */
        /* margin-right: auto;
        margin-left: auto; */
        background-color: #fff;
        box-shadow: 1px 0 25px 0 rgb(173 128 35 / 25%);
        z-index: 1000;
        max-height: 500px;
        overflow-y: scroll;
        padding: 20px 30px;
    
}

.contact_form::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .contact_form::-webkit-scrollbar-track {
    background: rgb(236, 236, 231);        /* color of the tracking area */
  }
  
  .contact_form::-webkit-scrollbar-thumb {
    background-color: rgb(139, 138, 134);    /* color of the scroll thumb */
    /* border-radius: 20px;       roundness of the scroll thumb */
    border: 3px solid rgb(221, 221, 213);  /* creates padding around scroll thumb */
  }
.contact_form.form-control{
    max-width: 500px;
}
.contact_Subtitle{
    margin-bottom: 16px;
    color: #191919;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
}
.contact_header {
    margin-bottom: 16px;
    padding-right: 9px;
    padding-left: 0;
    font-family: 'Heldane display',sans-serif;
    color: #191919;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
}

.carousel_app{
    display: flex;
    flex-direction: row;
 
    justify-content: space-around;
}
.Carousel-content img{
    width: 160px;
    height: 50px;
    cursor: pointer;
}
.Carousel-content h3 {
    color: #fff;
    font-size: 40px;
}
.Carousel-content p{
    color:black;
    font-size: 15px;
}

