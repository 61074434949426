@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
}

body {
    margin: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    color: #0F2D5A!important;
    font-weight: 400;
    font-family: 'Montserrat', 'Roboto', Arial, sans-serif!important;
}
.button--solid:disabled{
    background-color:#be9c90!important;
    cursor: not-allowed;
  } 
a {
    text-decoration: none;
}

.splash{position:fixed;z-index:2000;background:#ffffff;color:gray;top:33%;bottom:0;left:33%;right:0;overflow:hidden;}
.splash-title{text-align:center;max-width:500px;padding:20px}
.splash-title h1{font-size:26px}
.color-line{border-radius:4px 4px 0 0}
a:hover {
    text-decoration: none!important;
}

h2.title-card {
    font-size: 2.5rem;
    line-height: 1.1;
    color: #0F2D5A;
    margin-top: 5rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.care-header {
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 38px;
    box-shadow: inherit;
    transition: box-shadow 0.3s ease-in-out 0s;
}

.care-h-scrolled {
    position: fixed;
    z-index: 2;
    background-color: #fff!important;
    color: #0F2D5A!important;
    width: 100%;
    top: 34px;
    box-shadow: 0px 4px 8px rgba(10, 28, 64, 0.1), 0px 4px 4px rgba(10, 28, 64, 0.1);
    transition: box-shadow 0.3s ease-in-out 0s;
}

.care-h-scrolled .care-h-menu-link {
    color: #0F2D5A!important;
}

.care-h-scrolled a.care-h-menu-link:hover {
    color: #4369a1!important;
}

.care-h-scrolled .active {
    border-bottom: 1.5px solid #0F2D5A!important;
}

.care-h-inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 0px auto;
}

.care-h-link {
    width: 31%;
    position: relative;
    top: 6px;
    left: 30px;
    text-decoration: none;
}

.menu-icon {
    display: none;
}

.care-h-img {
    width: 22%;
}

.care-h-menu {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0px;
}

.care-h-menu-inner {
    display: flex;
    align-items: flex-start;
    align-self: center;
}

.care-h-menu-link {
    align-self: center;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    text-transform: capitalize;
    color: #fff;
    position: relative;
    margin: 0px 25px;
    text-decoration: none;
}
.care-h-menu-link .btn{
    font-size: 17px;
    color: #fff!important;
}
.care-h-menu-link .btn:focus{
    box-shadow: none!important;
}
.care-h-scrolled .care-h-menu-link .btn{
    color: #0F2D5A!important;
}
a.care-h-menu-link:hover {
    color: #32568b!important;
}

.active {
    /* border-bottom: 1.5px solid #0F2D5A; */
}

.care-banner {
    width: 100%;
    background-color: #8EC3C1;
    min-height: 552px;
    padding-bottom: 20px;
    position: relative;
    top: 35px;
}

.care-page-center {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
}

.care-banner-text {
    padding: 0 25px 25px;
    max-width: 650px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}
.depression-banner-text {
    padding: 0 25px 25px;
    max-width: 650px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}

.care-home-text {
    padding: 0 25px 25px;
    max-width: 750px;
    vertical-align: middle;
    background: #d1d1d1db;
    margin-top: 118px;
}
.care-home-text-depression {
    padding: 0 25px 25px;
    max-width: 750px;
    background: #d1d1d1db;
    margin-top: 118px;
    float: right;
    margin-right: 45px;
    height: 300px;
}
.care-banner h1 {
    font-weight: 600;
}

.care-banner h2 {
    font-weight: 600;
}

.care-banner p {
    max-width: 557px;
    font-size: 18px;
    line-height: 32px;
    white-space: normal;
    margin-top: 0;
    font-weight: 400;
}

.care-b-large {
    font-size: 48px;
    line-height: 64px;
    color: rgb(15, 45, 90);
    letter-spacing: 0.5px;
}

.care-banner ul {
    list-style: none;
    margin: 16px 0 0;
    padding: 0;
}

.care-banner ul li {
    display: inline-block;
    margin-right: 17px;
}

.care-banner-button {
    outline: 0;
    /* margin-top: 32px; */
    padding-top: 15px;
    padding-bottom: 15px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(255, 108, 55);
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #fff;
    text-decoration: none;
}
.care-banner-button-depression {
    outline: 0;
    margin-top: 35px;
    padding-top: 14px;
    padding-bottom: 15px;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    /* display: inline-block; */
    font-size: 16px;
    margin-right: 200px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.5px;
    float: right;
    color: rgb(255, 108, 55);
    text-decoration: none;

}

a.care-banner-button:hover {
    color: #eee;
}

.care-banner-works {
    color: #1fa5ff;
    text-decoration: none;
}

.care-banner-video {
    position: relative;
    width: 50%;
    padding: 7% 10% 3%;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: 100%;
    user-select: none;
    overflow: hidden;
}

.mind-video {
    width: 100%;
    height: 452px;
    object-fit: cover;
    border-radius: 10px;
    animation: 1s ease-out 0s 1 normal none running eMLfYp;
    /* background: url('../images/mind/mind-video.mp4') center center / cover no-repeat rgb(17, 17, 17); */
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-box-shadow: 0px 0px 16px 0px rgba(110, 110, 110, 1);
    -moz-box-shadow: 0px 0px 16px 0px rgba(110, 110, 110, 1);
    box-shadow: 0px 0px 16px 0px rgba(110, 110, 110, 1);
}

.mind-banner {
    background-color: #FED6A8!important;
}

.no-bg {
    background: none!important;
}

.home-banner {
    /* background: url(../images/home/home-banner.jpeg) no-repeat bottom; */
    background-size: 100%;
    min-height: 652px;
}

.care-products h1 {
    font-size: 32px;
    color: #635858;
    font-weight: 600;
}

.p-h6 {
    font-size: 18px;
    color: #635858;
    font-weight: 500;
    padding-bottom: 10px;
}

.care-products {
    padding: 0 60px;
    background-color: #7EBDBB;
    transition-delay: 1050ms;
}

.care-products-primary {
    padding: 0 60px;
    background-color: rgb(255, 255, 255);
    transition-delay: 1000ms;
}

.care-products p {
    font-size: 13px;
    color: #635858;
}

.care-products-inner {
    padding: 6em 4em;
}

.care-products-col {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
}

.care-products-sec {
    order: inherit;
    width: 45%;
    padding: 0 50px;
}

img.care-products-image1 {
    width: 100%;
    border-radius: 10px;
}

.products-text {
    order: inherit;
    width: 45%;
    margin-right: 4%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
}

.products-button {
    margin-top: 2.875em;
}

.products-button-link {
    background: #0f2d5a;
    color: #fff;
    font-size: 1em;
    line-height: 1;
    padding: .7em 1.1em;
    border-radius: 4px;
    outline: 0;
    box-shadow: none;
    margin: 0;
    cursor: pointer;
    border: 0;
    transition: box-shadow 150ms, transform 150ms;
    box-shadow: 0px 8px 16px rgba(10, 28, 64, 0), 0px 8px 8px rgba(10, 28, 64, 0);
    transform: none;
}

a.products-button-link:hover {
    color: #eee!important;
}

.products-header {
    margin-bottom: 0.5em;
    font-size: 3rem;
    line-height: 1.1;
    color: #90a6c7;
    font-weight: 700;
}

.products-def {
    font-size: 2.3rem;
    line-height: 1.5;
    font-weight: 600;
    color: #0F2D5A;
    margin-bottom: 1em;
}

.products-subdef {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    color: #0F2D5A;
}

.care-e-button {
    font-size: 18px;
    float: right;
}

.gallery-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70vh;
}

.care-item {
    flex: 1;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.8s ease;
}

.care-item:hover {
    flex: 7;
}

.section-content {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 24px;
    opacity: 0;
    transition: all .25s .1s ease-out;
}

.content-active {
    flex: 7;
    opacity: 1!important;
    transform: scaleX(1);
    color: #E74C3C;
}

.care-item-title {
    font-size: 24px;
    text-align: center;
    padding: 20px 5px;
    font-weight: 500;
    font-family: cursive;
}

.care-benefits {
    margin: 0 auto;
    max-width: 1000px;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-benefits-col {
    width: 50%;
    text-align: left;
}

.care-b-img img {
    width: 100%;
    border-radius: 13px;
}

.care-b-text {
    flex-shrink: 0;
    padding-left: 60px;
    margin-bottom: 0;
    max-width: 100%;
    color: #0F2D5A;
}

.care-b-links {
    margin-top: 30px;
    margin-left: 5%;
}

.care-b-button {
    background: #0f2d5a;
    font-size: 16px;
    margin-bottom: 24px;
    color: #eaeaea;
    padding: 12px 18px;
    border-radius: 4px;
}

.care-b-button:hover {
    color: #fff;
}

.care-b-button::after {
    content: "\203A";
    font-size: 120%;
    line-height: .75;
    display: inline-block;
    margin: 0 0 0 1em;
}

.benefit-content {
    display: flex;
    height: auto;
    margin-right: 30px;
    margin-bottom: 10px;
    align-items: center;
}

.benefit-h3 {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #75ce00;
}
.benefit-h4 {
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 20px;
    color: #0f2d5a;
}

.benefit-image {
    width: 5%;
    height: 100%;
    margin-right: 0px;
}

.benefit-image img {
    width: 100%;
}

.benefit-text {
    width: 85%;
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}

.benefit-text h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.care-getapp {
    width: 100%;
    height: 400px;
    background: #F1F5F8;
}

.care-g-section {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    flex-direction: column;
}

.g-title {
    font-size: 38px;
    line-height: 45px;
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
}

.g-subtitle {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
}

.g-appicons {
    display: flex;
    justify-content: center;
}

.g-appicons img {
    margin-right: 10px;
}

.g-app-img img {
    width: 100%;
}

.how-works {
    min-height: 850px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #b6effe;
    background-position: bottom;
}

.care-h-works {
    max-width: 650px;
    padding-bottom: 30px;
    padding-top: 60px;
}

.care-h-works-h2 {
    font-size: 38px;
    font-weight: 600;
}

.h-section {
    display: flex;
    flex-flow: row;
    padding: 20px;
}

.h-number {
    font-size: 120px;
}

.h-text {
    padding: 0 10px;
}

.h-text h3 {
    font-size: 30px;
}

.h-text p {
    font-size: 18px;
    text-align: left;
}

.h-header {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.h-works-img {
    margin-bottom: 20px;
    height: 180px;
}

.h-works-img img {
    width: 60%;
}

.care-h-button {
    padding-top: 38px;
    text-align: center;
    margin-bottom: 70px;
}

.care-footer {
    background: #242950;
    padding: 50px 45px;
}

.f-section {
    position: relative;
    display: -webkit-box;
    display: flex;
    width: 100%;
    justify-content: space-around;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1400px;
    padding: 0;
}

.care-footer-section {
    padding-bottom: 30px;
}

.care-f-header {
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    color: #565b73;
    margin-bottom: 6px;
    font-weight: 500;
    text-align: left;
    padding: 0;
    background: none!important;
}

.care-f-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.care-f-list {
    margin: 0
}

.care-f-link {
    font-size: 14px;
    font-weight: 400;
    color: #aeb0bb;
    display: block;
    padding: 4px 0;
}

.care-footer-bottom {
    background: #242950;
    margin-top: 0;
    text-align: center;
}

.care-footer-bottom ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.care-footer-bottom ul li {
    display: inline-block;
    color: #565b73;
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    padding: 0 12px;
    position: relative;
    margin: 10px 0 4px;
}

.care-footer-bottom ul li a {
    display: inline-block;
    color: #565b73;
    padding: 4px 12px;
    margin: -4px -12px;
}

.care-footer-bottom ul li::after {
    display: block;
}

.care-footer-bottom ul li:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    bottom: 4px;
    border-right: 1px solid rgba(86, 91, 115, .65);
}

.care-footer-bottom p {
    letter-spacing: .15em;
    text-transform: uppercase;
    font-size: 11px;
    color: #565b73;
    margin-bottom: 6px;
    font-weight: 500;
}

.scroll-heading {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
    margin-left: 7%;
}

.scroll-subheading {
    font-size: 16px;
    line-height: 1.7;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 20px;
    color: #0f2d5abd;
}

.scroll-heading::after {
    content: "";
    width: 90%;
    display: block;
    border-bottom: 1px solid #e8e8e8;
    margin-left: 10px;
}

.care-scroll {
    padding: 45px 0 15px 0;
    background-color: #FFD3A2;
    overflow: hidden;
}

.care-scroll:hover ul {
    animation-play-state: paused;
}

.care-scroll-section:first-of-type {
    transform: translateX(-10px);
    animation: careMoveLeft 70s linear infinite;
}

.care-scroll-section:nth-of-type(2) {
    justify-content: flex-end;
    transform: translateX(10px);
    animation: careMoveRight 70s linear infinite;
}

.care-scroll-section {
    display: -webkit-box;
    display: flex;
    list-style-type: none;
    will-change: transform;
    transform: translateZ(0);
    margin-bottom: 40px;
}

.care-scroll-section li {
    width: 200px;
    padding-right: 50px;
    flex-shrink: 0;
}

.care-scroll-section a {
    border-radius: 5px;
    overflow: hidden;
    display: block;
    transition: all .2s ease-in-out;
    will-change: transform, box-shadow, border-color;
}

.care-scroll-section header {
    background: #fff;
    text-align: right;
    padding: 14px;
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
    color: #001489;
}

.care-scroll-section main {
    padding: 14px 14px 24px;
}

.care-scroll-section .ico-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-position: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    top: -37px;
    margin-bottom: -15px;
}

.care-scroll-section ._title {
    color: #001489;
    height: 79px;
    font-size: calc(14px + 3*(100vw - 420px)/860);
    font-family: Whitney Cond SSm A, Whitney Cond SSm B, sans-serif;
    font-weight: 500;
    line-height: 1.2;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.care-scroll-section ._title::after {
    content: "";
    width: 40%;
    height: 2px;
    background: #001489;
    display: block;
    margin: 8px 0;
}

.care-scroll-section footer {
    color: #001489;
    height: 32px;
    font-size: calc(10px + 1*(100vw - 420px)/860);
    font-weight: 500;
    line-height: 1.5;
}

.social-links {
    margin-top: 20px;
    display: flex;
}

.social-links a {
    position: relative;
    display: flex;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
}

.career-highlight {
    border-radius: 1px;
    background: #f6f4f4;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 0 2px;
}

.coming-soon {
    border-radius: 1px;
    background: #fff;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 0 2px;
}

.care-f-link img {
    width: 100%;
}

.care-m-img {
    padding-left: 60px;
    padding-right: 40px;
}

.care-m-img img {
    width: 100%;
}

.have-q {
    color: #aeb0bb;
}

.have-q-one {
    font-size: 26px;
    line-height: 20px;
    margin-bottom: 15px;
}

.have-q-two {
    margin-bottom: 10px;
}

.offers {
    display: flex;
    flex-flow: row;
}

.care-offers {
    /* background: #fce5f4; */
    /* border:  2px solid #ffebf8; */
    margin: 20px;
    border-radius: 10px;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
}

.care-offers-img {
    position: relative;
    order: inherit;
    width: 36%;
    padding: 0 5px 0 40px;
}

.care-offers-text {
    position: relative;
    order: inherit;
    width: 58%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 28px;
}

.care-offers-text h2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #000;
    margin-top: 1em;
}

.care-offers-text h5 {
    color: #5e5d5f;
    font-size: .9em;
    font-weight: 500;
}

.offer-button {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgb(255, 108, 55);
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #fff!important;
    border: none;
}

.strike {
    text-decoration: line-through;
    color: #5e5d5f;
}

.free-session {
    font-size: 11px;
    color: #5e5d5f;
    font-weight: 500;
}

.care-discount {
    color: #5e5d5f;
    font-weight: 500;
}

.care-price {
    color: #000;
    font-size: 17px;
    font-weight: 500;
}

.dis-40 {
    background-color: #fce5f4;
}

.works {
    margin-bottom: 40px;
    background: #fbfbfb;
}

.works-heading {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    margin: 0!important;
    padding-top: 50px;
}

.works-care {
    /* margin-right: 4rem;
    margin-left: 4rem; */
    display: flex;
    flex-flow: row wrap;
}

.works-care-img {
    position: relative;
    order: inherit;
    width: 46%;
    padding: 0 5px 0 40px;
}

.works-care-text {
    position: relative;
    order: inherit;
    width: 48%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 28px;
}

.w-number {
    font-size: 7rem;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: 400;
}

.w-title {
    font-size: 26px;
    margin-bottom: 15px;
    line-height: 1.2;
    font-weight: 400;
}

.w-description {
    font-size: 1.2rem;
    margin-bottom: 0;
    line-height: 1.7;
    font-weight: 400;
    color: #0f2d5abd;
}

.w-blue {
    color: #3ecadd;
}

.w-green {
    color: #aeec73;
}

.w-pink {
    color: #fa476f;
}

.w-voilet {
    color: #664277;
}

.w-yellow {
    color: #d3d02e;
}

.talk-container {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-flow: column;
}

.talk-box-content {
    max-width: 600px;
    text-align: center;
    ;
}

.talk-box {
    padding: 0 30px;
    border-radius: 6px;
    text-align: center;
}

.t-box-sec {
    padding: 0 0 50px;
}

.t-box-sec h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.5;
}

.t-box-sec p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 40px;
}

.talk-button {
    background-color: #fa476f;
    color: #fff;
    border-radius: 27px;
    padding: 12px 14px;
    font-size: 16px;
    line-height: 1.5;
}

a.talk-button:hover {
    color: #fa476f;
    background-color: #fff;
}

.care-meet-links {
    margin-top: 30px;
}

.care-meet {
    margin-left: 3rem;
    margin-right: 3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-exp-bg {
    background-color: #f9e4de;
    /* background: #f9e4de url(../images/mind/mind-exp.jpg) no-repeat bottom right; */
}

.care-exp {
    margin-left: 3rem;
    margin-right: 3rem;
    align-items: center;
    min-height: 450px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 3rem!important;
}

.care-exp-col-one {
    width: 60%;
    text-align: left;
    z-index: 1;
}

.care-exp-col-two {
    width: 40%;
    text-align: left;
}

.care-exp-text {
    flex-shrink: 0;
    padding-left: 25px;
    margin-bottom: 0;
    max-width: 100%;
    color: #0F2D5A;
}

.care-exp-links {
    margin-top: 30px;
}

.care-exp-button {
    background: #0f2d5a;
    font-size: 16px;
    margin-bottom: 24px;
    color: #eaeaea;
    padding: 12px 18px;
    border-radius: 4px;
}

.care-exp-button:hover {
    color: #fff;
}

.care-exp-button::after {
    content: "\203A";
    font-size: 120%;
    line-height: .75;
    font-family: Consolas, Monaco, monospace;
    display: inline-block;
    margin: 0 0 0 1em;
}

.care-exp-img {
    position: relative;
    padding: 20px 40px;
}

.care-exp-img img {
    width: 100%;
}

.exp-h3 {
    font-weight: 600;
    font-size: 46px;
    margin-bottom: 20px;
}

.exp-text-para {
    font-size: 2.6rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0f2d5abd;
}

.photo-one {
    position: relative;
    width: 65%;
    top: 0;
    left: 0;
    z-index: 1;
}

.photo-one img {
    width: 100%;
}

.photo-two {
    position: absolute;
    width: 30%;
    top: -4rem;
    left: 61%;
}

.photo-two img {
    width: 100%;
}

.photo-three {
    position: absolute;
    width: 42%;
    bottom: -7rem;
    right: 0;
}

.photo-three img {
    width: 100%;
}

.faq-links {
    width: fit-content;
}

.faq-button {
    background: #0f2d5a;
    font-size: 16px;
    color: #eaeaea;
    padding: 12px 18px!important;
    border-radius: 4px;
    display: inline-block!important;
}

.meet-text {
    width: 85%;
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}

.meet-text-para {
    font-size: 1.1rem;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0f2d5abd;
}

.timer {
    color: #ff8f03;
}

.wh-one {
    background-color: #FFF1A2;
}

.wh-two {
    background-color: #EDFEED;
}

.wh-three {
    background-color: #5782f67e;
}

.pad-right-60 {
    padding-right: 60px;
}

.pad-left-60 {
    padding-left: 60px;
}

.pad-left-136 {
    padding-left: 136px;
}

.pad-right-136 {
    padding-right: 136px;
}

.middle-align {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-flow: column;
}

.middle-align img {
    width: 100%;
}

.care-h-menu-inner .icon {
    display: none;
}

.thanks-section {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.thanks-section h2 {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    padding-top: 10px;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
}
.l_care-header{
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    box-shadow: inherit;
    transition: box-shadow 0.3s ease-in-out 0s;
}
.left-spacing{
    padding-right: 15%;
}
@media (max-width: 1024px) {
    .care-h-link {
        width: 15%;
        position: relative;
        /* top: 22px; */
        left: 15px;
        text-decoration: none;
    }
    .care-h-menu-link {
        font-size: 15px;
        margin: 0px 1.75rem;
    }
    .care-exp-bg {
        background-size: 56%;
    }
    .care-h-img {
        width: 90px;
    }
    .care-b-large {
        font-size: 24px;
        line-height: 32px;
    }
    .care-banner-text {
        max-width: 500px;
    }
    .home-banner {
        background-size: 100%;
    }
    .care-banner-video {
        padding: 10% 6% 3%;
    }
    .mind-video {
        height: 333px;
    }
    .points-container{
        width: 95%!important;
    }
    
}

@media screen and (max-width: 600px) {
    .testimonial_container{
        display: flex;
        flex-direction: column!important;
    }
    .Carousel-content{
        width: 100%!important;
    }
    .Carousel-sl{
        width: 100%!important;
    }
    .care-h-inner {
        min-height: 60px;
    }
    .care-h-menu-inner a {
        display: none;
    }
    .care-h-menu-inner a.icon {
        margin-right: 20px;
        float: right;
        display: block;
    }
    .care-products {
        padding: 0 10px;
    }
    .care-products-inner {
        padding: 2rem 2rem;
    }
    .care-products-col {
        display: flex;
        margin-bottom: 30px;
    }
    .care-products-sec {
        width: 100%;
        padding: 20px!important;
    }
    .care-products-primary {
        padding: 0 10px;
    }
    .care-benefits {
        display: inline-block;
    }
    .care-banner {
        position: relative;
        top: 40px!important;
    }
    .productline{
        margin: 140px auto 0px!important;
    }
    .product-banner {
        position: relative;
        top: 40px!important;
    }
    .care-benefits-col {
        width: 100%
    }
    .care-b-text {
        padding-left: 20px;
    }
    .g-appicons img {
        margin-right: 10px;
        width: 30%;
    }
    .care-exp {
        display: inline;
    }
    .care-exp-col-one {
        width: 100%;
    }
    .care-exp-col-two {
        width: 100%;
    }
    .g-title {
        font-size: 32px;
        width: 90%;
        padding-bottom: 10px;
        text-align: center;
    }
    h2.title-card {
        font-size: 2.3rem;
    }
    .care-home-text {
        margin-top: 95px;
    }
    .exp-h3 {
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 5px;
    }
    .care-banner p {
        line-height: 22px;
    }
    .care-header {
        background: #fff;
    }
    .works-care {
        display: flex;
    }
    .works-care-img {
        order: 1;
        padding: 0 28px!important;
        width: 100%;
    }
    .works-care-text {
        order: 2;
        width: 100%;
        margin-bottom: 20px;
    }
    .pad-left-136 {
        padding: 0;
    }
    .pad-right-136 {
        padding: 0;
    }
    .pad-left-60 {
        padding-left: 28px;
    }
    .pad-right-60 {
        padding-right: 28px;
    }
    .care-meet {
        display: inline;
    }
    .care-m-img {
        margin-top: 40px;
        padding: 30px;
    }
    .exp-text-para {
        font-size: 2rem;
    }
    .care-exp-bg {
        background-size: 40%;
    }
    .f-section {
        display: inline;
    }
    .care-f-list {
        /* text-align: center; */
    }
    .have-q {
        text-align: center;
    }
    .social-links {
        display: flex;
        justify-content: center;
    }
    .care-footer-bottom a {
        font-size: 8px;
    }
    .care-banner-text {
        margin-top: 95px;
    }
    .depression-banner-text {
        margin-top: 225px!important;
    }
    .care-page-center {
        display: inline-block;
    }
    .care-banner-video {
        display: none;
        width: 100%;
    }
    .mind-video {
        height: 432px;
    }
    .sign-middle h2 {
        width: 80%!important;
        margin-top: 20px!important;
        font-size: 20px!important;
    }
    .sign-sub {
        font-size: 14px!important;
        padding: 0 30px;
    }
    .care-form {
        padding: 40px 30px!important;
    }
    .form-white-bg{
        padding:0!important;
    }
    .mission-h2 {
        margin-top: 130px!important;
    }
    .care-products-sec {
        order: 1;
        width: 100%;
    }
    .products-text {
        order: 2;
        width: 100%;
    }
    .active {
        border-bottom: none!important;
    }
    .care-h-scrolled .active {
        border-bottom: none!important;
    }
    .team-section {
        padding: 0!important;
    }
    .thanks-section h2 {
        width: 80% !important;
    }
    .offers {
        display: inline!important;
    }
    .care-offers {
        width: 90%!important;
    }
    .care-offers-text h2 {
        font-size: 14px!important;
        color: #000;
    }
    .care-offers-text h5 {
        font-size: 12px!important;
        color: #5e5d5f;
    }
    .blog-b-image img{
        width: 100%;
    }
    .blog-detailed-section{
        padding: 0 20px;
    }
    .blog-content-section{
        display: flex;
        flex-flow:column!important;
    }
    .blog-image-section{
        width: 90%!important;
        min-height: auto!important;
    }
    .blog-title{
        font-size: 2.25rem!important;
    }
    .steps-container{
        width: 95%!important;
    }
    .steps-heading{
        font-size: 25px!important;
        margin-bottom: 10px!important;
    }
    .segment-container{
        width: 95%!important;
    }
    .therapy-benefit-container{
        width: 95%!important;
    }
    .try-session-button button{
        font-size: 12!important;
    }

    .price-color{
        margin-top: 0!important;
    }
    .pricing-wrap{
        padding: 20px!important;
    }
    .productline{
        display: flex;
        flex-flow: column!important;
    }
    .product-sidebar{
        order: 1!important;
    }
    .productline-account{
        order: 2!important;
    }
    .button--solid{
        font-size: 1.0rem!important;
    }
    .care-popup-one{
        margin: 20px!important;
    }
    .service-container {
        display: flex!important;
        flex-flow: column!important;
    }
    .service-section {
        width: 100%!important;
        display: flex;
        flex-flow: column;
        padding: 10px 30px!important;
    }
    .mini-container{
        width: 95%!important;
    }
    
}

@media screen and (max-width: 600px) {
    .notification-text{
        padding: 5px!important;
        font-size: 11px!important;
    line-height: 2.6!important;
    }
    button.book-button{
        margin-left: .3rem!important;
    }
    .care-h-menu-inner.responsive {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        overflow: hidden;
        transform: translateY(0px) translateX(0px);
        transition: transform 250ms ease 0s;
    }
    .card-wrapper{
        width: 95%!important;
    }
    .card-details{
        width: 30%!important;
    }
    .card-wrapper h3 {
        font-size: 0.8em!important;
    }
    .card-wrapper p{
        font-size: .8em!important
    }
    .menu-icon {
        display: block;
        position: relative;
        float: right;
    }
    a.icon {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 18px;
    }
    .care-h-menu-inner.responsive {
        width: 100%;
        background: #fff;
        display: block;
        flex-flow: column;
        padding: 50px;
        min-height: 100vh;
        line-height: 20px;
        border-radius: 5px;
        box-shadow: 14px 14px 40px 0 rgba(118, 126, 173, 0.08);
    }
    .care-h-menu-inner.responsive a {
        float: none;
        display: block;
        text-align: left;
        border-bottom: none;
        min-height: 45px;
    }
    .care-h-scrolled .care-h-menu-link {
        color: #0F2D5A!important;
    }
    .care-h-menu-link {
        font-size: 15px;
        color: #0F2D5A!important;
    }
    .care-h-menu-link .btn{
        font-size: 15px;
        color: #0F2D5A!important;
    }
    .care-h-scrolled .care-h-menu-link .btn{
        color: #0F2D5A!important;
    }
    .no-afraid-container {
        padding: 0 20px;
    }
    .showline{
        overflow-x: auto !important;
    }
    .card_content{
        margin-bottom: 0!important;
    }
    .left-spacing{
        padding-right: 0;
    }
}

@media (max-width: 1024px) {
    .care-banner p {
        font-size: 13px;
    }
    .mind-banner {
        background-color: #FED6A8!important;
    }
    .care-banner {
        min-height: 432px;
    }
    .product-banner {
        min-height: 402px;
    }
}

@media only screen and (min-width : 601px) and (max-width : 991px) {
    .pop-c {
        font-size: 17px!important;
        font-weight: 500;
    }
    .pop-s {
        width: 95%;
        font-size: 12px!important;
        color: #414141;
        font-weight: 400;
        line-height: 1.7;
    }
    .sign-middle h2 {
        font-size: 20px!important;
    }
    .border-r{
        border-right: none!important;
        border-bottom: 1px solid #d0cfcfe0!important;
        width: 95%!important;
        margin: auto!important;
    }
    .card-wrapper{
        width: 80%!important;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1020px!important;
    }
}

#faq {
    padding: 60px 0;
    overflow: hidden;
    background: #f8f8f8;
}

#faq #faq-list {
    padding: 0;
    list-style: none;
}

#faq #faq-list li {
    border-bottom: 1px solid #ebebeb;
}

#faq #faq-list a {
    padding: 22px 0;
    display: block;
    position: relative;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    padding-right: 20px;
}

#faq #faq-list a:focus {
    outline: none;
    text-decoration: none;
}

#faq #faq-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 20px;
}

#faq #faq-list p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #767683;

}

@media (max-width: 768px) {
    #faq #faq-list a {
        font-size: 18px;
    }
    #faq #faq-list i {
        top: 13px;
    }
}

#faq #faq-list a.collapse {
    color: #1bb1dc;
}

#faq #faq-list a.collapsed {
    color: #0F2D5A;
}

#faq #faq-list a.collapsed i::before {
    content: "\f2c7" !important;
}

@keyframes careMoveLeft {
    100% {
        transform: translateX(-2600px);
    }
}

@keyframes careMoveRight {
    100% {
        transform: translateX(2600px);
    }
}


/* about us */

.care-mission {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    min-height: 452px;
}

.mission-h6 {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

.mission-h2 {
    max-width: 790px;
    margin-top: 20px;
    margin-bottom: 44px;
    font-size: 68px;
    line-height: 52px;
    text-align: center;
    font-weight: 600;
}

.mission-p {
    max-width: 700px;
    margin-bottom: 20px;
    color: #05253a;
    line-height: 25px;
    text-align: center;
}

.mission-bold {
    font-size: 18px!important;
    font-weight: 400;
}

.team-header {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    padding: 30px 0;
    margin: auto;
    max-width: 798px;
    display: block;
}

.team-header-one {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    padding: 0 0 30px;
    margin: auto;
    max-width: 798px;
    display: block;
}

.team-subheader {
    text-align: center;
    font-size: 15px;
    line-height: 29px;
    margin: auto;
    max-width: 798px;
    display: block;
}

.tech-member {
    position: relative;
    padding: 16px 25px 5px;
    margin-top: 35px;
    /* background: #fff; */
    transition: 250ms;
    margin-bottom: 45px;
    border-radius: 4px;
}

.team-section {
    margin-top: 24px;
    margin-left: 0;
    margin-right: 0;
    padding: 0 50px;
}

.care-member {
    position: relative;
    padding: 16px 25px 5px;
    margin-top: 35px;
    background: #fff;
    transition: 250ms;
    margin-bottom: 45px;
    border-radius: 4px;
}

.tech-team-img {
    display: flex;
    margin-top: 6px;
    margin-bottom: 20px;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.tech-team-img img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    border-radius: 100px
}

.tech-team-name {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.tech-team-position {
    margin-bottom: 10px;
    color: #8a94a6;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.care-member .care-member-img {
    display: block;
    margin: 0 auto -50px;
    position: relative;
    top: -50px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 5px solid #fff;
    background-size: cover;
    background-repeat: no-repeat;
}

.care-member .care-member-name {
    text-transform: uppercase;
    font-size: 16px;
}

.care-member .care-member-name {
    text-transform: uppercase;
    font-size: 16px;
}

.care-member .care-member-position {
    display: block;
    color: #c6c6c6;
    padding-bottom: 12px;
    border-bottom: 1px solid #c6c6c6;
}

.care-read-more .more-text {
    display: none;
}


/* .read-more{
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
  }
  .read-more:hover{
    color: #a16304;
  }
  .read-less{
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
  }
  .read-less:hover{
    color: #a16304;
  } */

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    /* overflow-y: auto; */
}

.soverlay {
    visibility: visible;
    opacity: 1;
}

.open {
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.care-popup {
    width: 50%;
    margin: 100px auto;
    border-radius: 10px;
    padding: 30px 30px 10px;
    background: #fff;
    transition: all 5s ease-in-out;
}
.care-popup-one {
    border-radius: 10px;
    padding: 30px 30px 40px;
    background: #fff;
    transition: all 5s ease-in-out;
}

.care-popup-one h3 {
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-size: 14px;
    color: #333;
}

.care-content {
    margin-top: 28px;
}

.pop-c {
    font-size: 24px;
    font-weight: 500;
}

.pop-c span {
    color: rgb(255, 108, 55);
}

.pop-s {
    width: 95%;
    font-size: 13px;
    color: #414141;
    font-weight: 400;
    line-height: 1.7;
}

.pop-start {
    margin-top: 20px;
    max-width: 320px;
}

.pop-agreement {
    font-size: 10px;
    color: #9ca0a9;
}

.pop-button {
    margin-top: 20px;
    width: 100%;
}

.pop-start-link {
    background: rgb(255, 108, 55);
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.pop-start-link:hover {
    background: rgb(221, 95, 49);
    color: #fff;
}

.noscroll {
    overflow: hidden;
}

.care-popup .close {
    position: relative;
    top: -30px;
    right: -25px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.care-popup .close:hover {
    color: rgb(255, 108, 55);
}

.care-popup .content {
    max-height: 30%;
}
.care-popup-one .content {
    max-height: 30%;
}
.diff-amount{
    font-size: 18px;
    line-height: 27px;
    padding: 20px 99px;
}

@media screen and (max-width: 700px) {
    .care-popup {
        width: 90%;
        /* display: none; */
        margin: 50px auto!important;
    }
    .diff-amount{
        padding: 0!important;
    }
}
@media screen and (max-width: 900px) {
   
    .diff-amount{
        padding: 0!important;
    }
}


/* signup */

.s-bg {
    background-color: #FED6A8!important;
}

.sign-middle {
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; */
    margin-top: 10px;
}

.sign-middle h2 {
    text-align: center;
    margin: 0 auto;
    width: 50%;
    padding-top: 10px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
}

.caption {
    text-align: center;
    padding-bottom: 30px;
}

.sign-sub {
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 25px;
}

.care-form {
    background: #f1f1f1;
    border: 1px solid #e0e0df;
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    box-shadow: 0 1px 4px #dfe1e5;
}

.form-group {
    width: 100%;
}

input.form-control {
    height: 40px;
    margin: 14px;
    border-radius: 2px;
    border: 2px solid #fafbfc;
    background: #fafbfc;
    box-shadow: 0 1px 4px #9e9fa0;
}

input.form-control:hover {
    border-radius: 3.01px;
    border: 2px solid #dfe1e5;
    background: #dfe1e5;
}

input.form-control:focus {
    outline: none;
    border-width: 1px;
    border-color: #4C9AFF;
    background: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.signup-button {
    background: rgb(255, 108, 55);
    color: #fff;
    padding: 10px 30px;
    text-align: center;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    border: none!important;
}

.signup-button:hover {
    background: rgb(221, 95, 49);
    color: #fff;
}

.back-button {
    border-radius: 1px;
    background: #fff;
    color: #0F2D5A;
    font-weight: 600;
    font-size: 11px;
    margin-left: 4px;
    padding: 5px;
}


/* readmore */

.showmore {
    font-size: 1.1em;
    margin-top: 1.5em;
}

.showmore .more,
.showmore.show .dots {
    display: none
}

.showmore.show .more {
    display: inline
}

.showmore a {
    display: flex;
    justify-content: center;
    margin: 22px 30px;
    padding: 5px 15px;
    color: #ef9309;
    font-weight: 600;
}

.showmore a:hover {
    color: #a16304;
}

.not-strike {
    text-decoration: line-through;
}

.notification-bar {
    position: fixed;
    width: 100%;
    top: 0;
    margin: 0;
    z-index: 2;
}

.notification-text {
    background-color: #0f2d5a;
    padding: 10px;
    color: #6fd506;
    text-align: center;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    font-family: "Roboto",sans-serif;
    -webkit-animation: initiate .35s ease;
    animation: initiate .35s ease;
}
.not-underline{
        text-decoration: underline !important;

}
.notification-bar input {
    display: none;
}

.notification-bar label {
    cursor: pointer;
    color: #fff;
    position: absolute;
    z-index: 5;
    display: inline-block;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.notification-bar label[for=hide] {
    right: 15px;
    top: 11px;
    width: 24px;
    height: 24px;
    -webkit-animation: initiate 350ms ease;
    -moz-animation: initiate 350ms ease;
    -o-animation: initiate 350ms ease;
    animation: initiate 350ms ease;
}


/* Function */

.notification-bar input[value=show]:checked~label[for=show],
.notification-bar input[value=hide]:checked~label[for=hide],
.notification-bar input[value=hide]:checked~.notification-text {
    -webkit-transition: ease 350ms;
    -moz-transition: ease 350ms;
    -o-transition: ease 350ms;
    transition: ease 350ms;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.notification-bar input[value=hide]:checked~label[for=show],
.notification-bar input[value=show]:checked~label[for=hide],
.notification-bar input[value=show]:checked~.notification-text {
    -webkit-transition: ease 350ms;
    -moz-transition: ease 350ms;
    -o-transition: ease 350ms;
    transition: ease 350ms;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
}
/* terms */
.care-terms{
    margin-top:150px;
    margin-bottom: 50px;
}
.care-terms a{
    color:#3ecadd;
    text-decoration: none!important;
}
.care-terms p {
    color: #565656;
    text-align: justify;
    font-size: 13px;
    line-height: 22px;
}
.care-terms strong{
    font-weight: 600;
}
.terms-header{
    letter-spacing: -0.5px;
    color: #0d2366;
    margin-bottom: 6px;
    text-align: center;
    font-size: 36px;
    line-height: 48px;
    font-weight: 600;
}
.care-terms h1::after{
    content: '';
    display: block;
    width: 48px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}
.care-terms h3::after{
    content: '';
    display: block;
    width: 24px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
}
.terms-sub-header{
    letter-spacing: .5px;
    color: #0d2366;
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 32px;
}
.terms-sub-hints{
    padding: 0 20px;
}
/* blog */
.blog-content{
    width: 100%;
    margin-top:100px;
    padding-top: 60px;
    padding-bottom: 60px;
}
.blog-content-section{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
}
.blog-image-section{
    width: 60%;
    height: auto;
    flex: 1 1 auto;
    min-height: 380px;
}
.blog-image-section img{
    width: 100%;
}
.blog-body-section{
    margin-top: 20px;
    padding: 0 0 0 2rem;
    flex-basis: 34.33333%;
    justify-content: center;

}
.blog-body-section a{
    color: #000;
    text-decoration: none;
}
.blog-body-section a:hover{
    color: #000;
    text-decoration: none;
}
.blog-title{
    font-size: 3.25rem;
    font-weight: 600;
    line-height: 1.15em;
    margin: 0 0 .4em;
}
.blog-story{
    font-size: 1.75rem;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 6em;
    margin-bottom: 20px;
}
.b-user-section{
    display: flex;
    flex-flow: row;
    flex:1 auto;
}
.user-image img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.user-profile{
    padding: 0 10px;
    display: flex;
    flex-flow: column;
}
.user-profile span{
    font-size: 1.25rem;
    color: #000;
}
.blog-detailed-section{
    max-width: 740px;
}
.blog-detailed-section ul{
    list-style-type: none;
}
.blog-detailed-section ul li{
    padding: 10px 0;
    color: #6c757d;
    font-size: 17px;
}
.blog-detailed-section h2{
    color: #6c757d;
    text-align: center;
}
.bd-top{
    padding-bottom: 20px;
    border-bottom: 1px solid #6c757d;
}
.bd-top h1{
    color: #111;
    margin-bottom: 20px;
    font-size: 3.65rem;
    font-weight: 700;
}
.blog-detailed-section p{
    color: #6c757d;
    margin-bottom: 20px;
    font-size: 1.75rem;
    line-height: 1.75;
    text-align: justify;
}
.no-afraid-container {
    margin: 60px auto;
    max-width: 770px;
}
.no-afraid-container p{
    color: #6c757d;
    margin-bottom: 20px;
    font-size: 1.75rem;
    line-height: 1.75;
    text-align: justify;
}
.bd-bottom{
    display: flex;
    flex-flow: row;
    padding: 30px 0;
}
.share-media{
    display: flex;
    flex-flow: row;
    align-items: center;
}
.share-media a{
    padding: 0 5px;
}
.share-media img{
    width: 25px;
}
.bd-content-section{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center
}
.blog-b-image {
    margin-bottom: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.blog-b-image img{
    max-height: 480px;
    max-width: 820px;
}

.blog-thumbnail-section{
    margin: 20px auto;
    display: flex;
    flex-flow: column;
}
.blog-th-image{
    height: 200px;
    overflow: hidden;
}
.blog-th-image img{
    width: 100%;
    height: 100%;
}
.blog-th-title{
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.15em;
    margin: 0.3em 0 .4em;
    overflow: hidden;
    max-height: 4.5em;
    /* text-align: justify; */
}
a.blog-black{
    color: #000;
    text-decoration: none;
}
a.blog-black:hover{
    color: #000;
    text-decoration: none;
}
.blog-th-story {
    font-size: 1.75rem;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 6em;
    margin-bottom: 20px;
}
/* Account */
.a-bg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    background: #fff;
    width: 100%;
    height: 65px;
}
.showline{
    position: fixed;
    top: 63px;
    left: 0;
    z-index: 15;
    border-top: 1px solid #fafafa;
    box-shadow: 0px 4px 4px rgba(19,20,22,0.02);
    background: #fff;
    width: 100%;
    height: 55px;
}
.showline-list{
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    color: #c1c1c1;
    padding: 0;
    height: 55px;
    width: max-content;
}
.showline-list span{
    padding: 0 10px;
}
.list-active{
    color: #0F2D5A;
}
.list-active::after{
    content: '';
    display: block;
    width: 95px;
    height: 3px;
    background: #49dab5;
    margin-top: 4px;
}
.productline{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 140px auto;
    padding: 0 24px;
    width: 100%;
    /* max-width: 975px; */
}
.productline-account{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    /* max-width: 510px; */
    /* margin-left: 55%; */
}
.account-header{
    position: relative;
    margin-bottom: 10px;
    font-weight: 400;
    max-width: 710px;
    text-align: center;
}
.account-description{
    line-height: 24px;
    font-size: 14px;
}
.product-sidebar{
    flex-shrink: 0;
    width: 100%;
    max-width: 325px;
}
.pricing-wrap{
    display: flex;
    flex-flow: column;
    padding: 120px 20px 20px;
}
.pricing-wrap .title{
    margin: 0 0 15px;
    border-bottom: 1px solid #fafafa;
    padding: 0 0 10px;
    font-weight: 400;
}
.pricing-product{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pricing-product{
    margin: 17px 0;
    padding-bottom: 17px;
    border-bottom: 1px solid #dee0e3;
}
.p-product-content{
    margin: 0 0 10px;
    font-size: 16px;
}
.p-product-image{
    flex-shrink: 0;
    width: 100px;
}
.p-product-image img{
    width: 100%;
}
.p-product-price p{
    text-align: right;
}
.pricing-total{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* checkout-form-starts */
.form-wrapper {
    /* width: 440px; */
    margin: auto;
}
.contact-form-wrapper, .email-only-form-wrapper, .subscribe-form-wrapper {
    position: relative;
    overflow: hidden;
}
.form-white-bg {
    padding: .9375rem;
    border-radius: 5px;
    background-color: #fff;
}
.form-wrapper h3 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.25;
    font-size: 2.25rem;
}
.form-wrapper fieldset {
    margin: 0;
    padding: 0;
}
.name-field:first-child {
    margin-left: 0;
}
.name-field:last-child{
    margin-left: 5px;
}
.name-field-wrapper{
width: 100%;
}
.name-field {
    width: 49.1%;
    display: inline-block;
}
.form-field {
    font-size: 0;
    position: relative;
    border-radius: 3px;
    border: solid 1px #797979;
    clear: both;
    will-change: margin-bottom;
    margin-bottom: 20px;
    transition: margin-bottom .15s ease;
}
.form-field [class^=icon-] {
    top: 18px;
    left: 12px;
    color: #ccc;
    font-size: 1.5rem;
}
.form-field input, .form-field select, .form-field textarea {
    width: 100%;
    padding: 20px 10px 15px 20px;
    color: #4d4d4d;
    font-size: 1.5rem;
    font-family: National-Book,Helvetica,sans-serif;
    transition: top .2s ease,font-size .2s ease,padding .2s ease;
    border: none;
    border-radius: 2px;
    outline: 0;
}
.form-field input:focus~.form-placeholder, .form-field input~.form-placeholder.placeholder-fix, .form-field select:focus~.form-placeholder, .form-field select~.form-placeholder.placeholder-fix, .form-field textarea:focus~.form-placeholder, .form-field textarea~.form-placeholder.placeholder-fix {
    top: 10px;
    font-size: .6875rem;
    padding: 0 10px 20px 46px;
}

.form-field .form-placeholder, .form-field [class^=icon-] {
    position: absolute;
}
.form-placeholder {
    left: 0;
    top: 18px;
    color: #616060;
    font-weight: 500;
    pointer-events: none;
    transition: top .2s ease,font-size .2s ease,padding .2s ease;
    font-size: 13px;
    padding: 0px 10px 10px 20px;
}
.error-wrapper {
    float: left;
    text-align: left;
    padding: 5px 0;
    margin-left: 45px;
    opacity: 0;
    transition: opacity .1s ease .1s;
}
.hide {
    display: none!important;
}
.field-dropdown .icon-arrow-down {
    right: 20px;
    left: auto;
    font-size: .8rem;
    color: #ccc;
    top: 45%;
}
.button--solid {
    background: #0F2D5A;
}
.button--block {
    width: 100%;
}
.button--solid {
    color: #fff!important;
    padding: 10px 30px;
    font-size: 1.1rem;
    vertical-align: bottom;
    min-width: 160px;
}
.button {
    font-family: Sailec-Bold,Helvetica,sans-serif;
    cursor: pointer;
    border: none;
    outline: 0;
    border-radius: 3px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    transition: background-color .15s ease-in;
    white-space: normal;
}
.signup-terms.align-center {
    margin: 1rem 0;
}
 input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
[class*=' icon-'], [class^=icon-] {

    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.contact-form-wrapper h2, .contact-form-wrapper h3, .contact-form-wrapper p, .email-only-form-wrapper h2, .email-only-form-wrapper h3, .email-only-form-wrapper p, .subscribe-form-wrapper h2, .subscribe-form-wrapper h3, .subscribe-form-wrapper p {
    transition: opacity 350ms ease,transform 350ms ease;
}
label {
    display: block;
    margin: 0 0 .41666667rem 0;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    /* text-transform: Capitalize !important; */
}
:focus {
    outline: -webkit-focus-ring-color auto 1px;
}
/* checkout form end */
.care404-img{
    display: flex;
    flex-flow: column;
    max-width: 786px;
    align-items: center;
    margin: 60px auto;
    padding-top: 130px;
}
.care404-img img{
    width: 100%;
}
.care404-img h2{
    font-size: 24px;
    line-height: 1.7;
    text-align: center;
}
.go-top{
    position: fixed;
    bottom: 100px;
    right: 4%;
    z-index: 5;
    color: #000;
    visibility: hidden;
    opacity: 0;
}
.t-show{
    visibility: visible;
    opacity: 1;
}
.go-top-vertical{
    color: #000;
    font-size: 2rem;
    letter-spacing: .125rem;
    margin: 15px 0 0;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
}
.return-section{
    margin-top: 40px;
    margin-bottom: 40px;
}
.return-align{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.return-section a{
    display: inline-block;
    padding: 11px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #0F2D5A;
    line-height: 20px;
    border-style: none;
    background-color: #fafbff;
}
.return-section a:hover{
    background-color: #b4b4b4;
}

/* products */
.product-banner {
    width: 100%;
    min-height: 500px;
    padding-bottom: 20px;
    position: relative;
    top: 35px;
}
.product-banner p{
    font-size: 17px;
    line-height: 1.7;
}
.steps-container{
    width: 70%;
    margin: 0 auto 120px;
    padding-top: 35px;
}
.steps-heading{
    text-align: center;
    font-size: 35px;
    margin-bottom: 40px;
    font-weight: 300;
}
.steps-content{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.steps-image{
    padding: 20px;
}
.steps-image img{
    width: 100%;
    height: 250px;
    border-radius: 30%;
}
.steps-text{
    display: flex;
    flex-flow: row;
    padding: 0 20px;
    align-items: center;
}
.s-number{
    font-size: 40px;
}
.s-text{
    font-size: 18px;
    padding: 0 10px;
    color: #767683;
}
.segment-container{
    width: 85%;
    margin: 0 auto 120px;
    box-shadow: 0 4px 18px 3px #D6D3EA;
    border-radius: 9px;
    background-color: #fff;
}
.con-section{
    padding: 5px 35px 30px;
}
.border-r{
    border-right: 1px solid #d0cfcfe0;
}
.con-section h1{
    font-size: 20px;
    font-weight: 600;
    line-height: 40px
}
.con-section p{
    color: #767683;
    font-size: 14px;
}
.therapy-benefit-container{
    width: 40%;
    margin: 0 auto 40px;
    padding-top: 30px;
}
.therapy-benefit-container h2{
    font-size: 26px;
    font-weight: 500;
    text-align: center;
}
.therapy-benefit-section{
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 25px;
    border-radius: 10px;
}
.therapy-b-image{
    width: 75px;
    height: 75px;
    padding: 10px;
}
.therapy-b-image img{
    width:100%;
    border-radius: 50%;
}
.therapy-b-content{
    color: #767683;
    font-size: 16px;
    font-weight: 600;
}
.plan-container{
    width: 75%;
    margin: 0 auto 90px;
}
.plan-sh{
    box-shadow: 0 5px 14px 0 #a9a6a6d4;
    border-radius: 7px;
}
.plan-section{
    display: flex;
    flex-flow:column ;
    justify-content: center;
    min-height: 580px;
}
.recommend-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
.recommend-label{
    border-radius: 2px;
    padding: 1.25rem 1.5rem;
    margin-bottom: 1rem;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    background: #f99f05;
    color: #0F2D5A;
}
.recommend-box{
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}
.plan-content{
    margin: 20px 35px;
}
.plan-content h1{
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #c3c2c2;
    color: #f99f05;
    padding-bottom: 10px;
    padding-top: 10px;

}
.plan-content p{
    font-size: 14px;
    color: #767683;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 10px;

}
.price-strike{
    font-size: 19px;
    text-decoration: line-through;

}
.price-original{
    font-size: 44px;
    line-height: 42px;
    font-weight: 500;
    color: #f99f05;
}
.price-discount{
    font-size: 16px;
    font-weight: 500;
}
.price-section{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px 20px;
}
.price-section span{
    margin-bottom: 10px;
}
.price-grab {
    font-weight: 600;
    font-size: 1em;
    text-align: center;
}
button.price-start{
    background: #3e3c86;
    color: #fff!important;
    font-weight: 600;
    font-size: 14px;
    /* padding: 11px 20px; */
    text-align: center;
    /* height: 42px; */
    border-radius: 5PX;
    border: none!important;
    text-transform: uppercase;
    /* width: 190px;
    line-height: 42px; */
    padding: 11px 7px;
}
button.price-start:hover{
    color: #fff!important;
}
.price-button{
    padding: 20px 0 0;
}
.m-bottom{
    margin-bottom: 10px;
}
.price-color{
    background-color: #0F2D5A;
    color: #fff;
    border-radius: 5px;
}
.price-color p{
    color:#fff!important;
}
span.price-call {
    text-align: center;
    padding: 0 10px;
}
.price-h{
    color: #055bf9;
    text-align: center;
}
.plan-sh:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.try-session{
    display: flex;
    flex-flow: column;
    width: 75%;
    margin: 20px auto;
}
.try-session-content{
    font-size: 26px;
    text-align: center;
    padding-top: 10px;
    font-weight: 300;
}
.try-session-content strong{
    font-weight: 700;
}
.try-session-button{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.try-session-button button{
    background: #3e3c86;
    color: #fff!important;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    height: 42px;
    padding: 0px 11px;
    border-radius: 5PX;
    border: none!important;
    text-transform: uppercase;
    line-height: 42px;
}
.try-session-button button:hover{
    color: #fff!important;
}
.future-sessions-p{
    margin-top: 10px;
    text-align: center;
    color: #0F2D5A;
    font-size: 18px;
}
.future-sessions-span{
    text-align: center;
    color: rgb(255, 108, 55);
    font-size: 12px;
    display: block;
}
ul.unordered {
    list-style-type: disc;
}
.blog-sha {
    box-shadow: 0 4px 18px 3px #D6D3EA;
    padding: 10px;
}
/* services */
h2.service-title-card {
    font-size: 3.2rem;
    line-height: 1.1;
    color: #90a6c7;
    margin-top: 5rem;
}
.service-container{
    display:flex ;
    flex-flow:row;
    align-items: center;
    background: aliceblue;
    border-radius: 50px;
    margin-bottom: 30px;
}
.service-section{
    width: 50%;
    display: flex;
    flex-flow: column;
    padding: 30px;
}
.service-body{
    display:flex ;
    flex-flow:column;
    margin: auto;
}
.careme-why {
    min-height: 120px;
}
.sr-body{
    display: flex;
    flex-flow: row;
}
.sr-body-content{
    padding: 0 40px;
    font-weight: 500;
}
.sr-number{
    font-size: 45px;
    color: rgb(248, 8, 8);
}
.sr-c{
    font-size: 45px;
    color: rgb(248, 8, 8);

}
.sr-g{
    font-size: 45px;
    color: rgb(8, 248, 48);

}
.sr-number-green{
    font-size: 21px;
    color: rgb(8, 248, 48);
}
.sr-text{
    font-size: 18px;
}
.sr-middle-body {
    text-align: center;
    padding: 20px 0;
}
.service-mini-b{
    margin: auto;
    text-align: center;
    padding: 20px;
}
.points-container{
    width: 85%;
    margin-bottom: 60px;
}
.points-container p{
    font-size: 16px;
    line-height: 1.7;
    color: #0f2d5abd;
    margin-top: 30px;
}
.points-section{
    box-shadow: 0 2px 16px 0 #D3D5DA;
    border-radius: 6px;
    padding: 8px;
    width: 50%;
    margin: 15px;
}
.points-area{
    display: flex;
    flex-flow: row;
    margin: auto;
    padding-bottom: 20px;
}
.points-area ul{
    margin: auto;
}
.points-area ul li{
    line-height: 2.1;
}
.points-section h2 {
    font-size: 22px;
    padding: 0px 45px 15px;
}
.service-h3 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 20px;
}
.service-help {
    display: flex;
    height: auto;
    margin: auto;
    margin-right: 30px;
    margin-bottom: 10px;
    align-items: center;
}
.service-text{
    height: 100%;
    margin-left: 8px;
    padding-left: 0px;
}
.service-text h4{
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.therapy-help {
    line-height: 1.5;
    margin-left: 7%;
}
.service-text-help{
        height: 100%;
        margin-left: 8px;
        padding-left: 0px;
}
.service-text-help h4{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}
.margin-tp{
    margin-top: 30px;
}
.mini-container{
    width: 75%;
    margin: 20px auto;
    padding: 40px;
    min-height: 170px;
}
.mini-section{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.mini-section p{
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    text-align: center;
}
.section-left{
    align-items: baseline!important;
}
/* testimonials */

.tes-section{
    max-width: 390px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
}
.tes-content{
    background-color: #fafafa;
    padding: 12px 16px;
    margin: 0 0 10px;
    border-radius: 2px;
    border: 1px solid;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fafafa;
}
.tes-content p{
    font-size: .85em;
}
.tes-date {
    font-size: 11px;
    color: #33333382;
}
.tes-container{
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.tes-container h1::after{
    content: '';
    display: block;
    width: 48px;
    height: 4px;
    background: #49dab5;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
}
.blog-grow{
    display: flex;
    flex-flow: wrap;
}

button.book-button {
    padding: 0 6px;
    border-radius: 4px;
    background-color: #ff6c37;
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #fff!important;
    margin-left: 1rem;
    border: none;
}
.clinical{
    /* background-image: url('../images/mind/Psychiatrist.jpg'); */
    background-size: 100%;
}
.consultation{
    /* background-image: url('../images/mind/therapist.jpg'); */
    background-size: 100%;
}
button{
    border: none;
}
.program-banner{
    /* background-image: url('../images/products/relationship-counselling.jpg'); */
    background-repeat: round;
}
.banner-depression{
    /* background-image: url('../images/home/Depression+pic.jfif'); */
    /* background-size: 100%; */
   
}
.banner-bac{
    /* background-image: url('../images/home/mind-background.jpg'); */
    background-size: 100%;
}
.banner-bac-home{
    /* background-image: url('../images/home/mind-banner.jpeg'); */
    
}
.works-bac{
    /* background-image: url('../images/home/how-it-works.jpg'); */
}
.PhoneInput{
    width: 100%;
}
.PhoneInputInput{
    height: 40px;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 2px;
    border: 2px solid #fafbfc;
    background: #fafbfc;
    box-shadow: 0 1px 4px #9e9fa0;
}
.pop-otp {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 2.5rem;
}
.pop-otp button{
    margin-top: 1.5rem;
    background-color: transparent;
}
.otp-section{
    display: flex;
    flex-flow: column;
    align-items: center;
}
.otp-section input{
    width: 42px!important;
    height: 42px!important;
    margin: 5px;
}
.otp-section button{
    margin-top: 1.5rem;
    padding: 7px 50px;
    background-color: #ec9004;
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}
.otpClose{
    text-align: right;
    padding: 1rem;
}
/* Inital Animation */

@-webkit-keyframes initiate {
    0% {
        -webkit-transform: translateY(-100%);
    }
    50% {
        -webkit-transform: translateY(-50%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes initiate {
    0% {
        -moz-transform: translateY(-100%);
    }
    50% {
        -moz-transform: translateY(-50%);
    }
    100% {
        -moz-transform: translateY(0%);
    }
}

@-o-keyframes initiate {
    0% {
        -o-transform: translateY(-100%);
    }
    50% {
        -o-transform: translateY(-50%);
    }
    100% {
        -o-transform: translateY(0%);
    }
}

@keyframes initiate {
    0% {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0%);
    }
}

.product_card{
    margin-left: 12%;
    margin-top: 5%;
}
.card-wrapper {
    /* background-color: #fff; */
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    max-height: 120px;
    overflow: hidden;
    display: flex;
    width: 50%;
    margin-top: 10px;
    transition: max-height 500ms ease-in-out;  
    cursor: pointer;
    /* -webkit-box-shadow: 1px 0px 15px 3px #545759; 
    box-shadow: 1px 0px 15px 3px #545759; */
    
  }
  /* .card-wrapper:hover {
    box-shadow: 0 0 8px #d3d3d3;    
    cursor: pointer;
    max-height: 700px;
  } */
  .card_content a {
    display: contents;
    width: 100%;
  }
  .card-wrapper-wrap {
    box-shadow: 0 0 8px #d3d3d3;    
    cursor: pointer;
    min-height: 400px;
    padding: 2rem;

    margin-left: 160px;
    max-width: 65%;  
    margin-top: 30%;

    text-align: center;

  }
  .card-wrapper h3 {
	opacity: 1;
    margin: 0;
    font-size: 1.3em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card-wrapper p {
	letter-spacing: 1px;
	margin: 5px 0;
    opacity: 0.6;
}
  .card-details{
    background-color: #2A265F;
	color: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
	width: 25%;
    position: relative;
  }
  .reveal-details {
    font-weight: 300;
  }
  .program-description{
    padding: 18px;
	position: relative;
	width: 100%;
  }
  
  /* // .reveal-details {
  //   visibility: hidden;
  //   overflow: hidden;
  //   max-height: 0;
  //   transition: 200ms ease-in-out;
  // }
   */
  
  .card-height-animate {
    height: 300px; 
  }

  .card_content{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      width:100%;
  }

.language-select{
    position: absolute;
    right: 15px;
    z-index: 100;
}  
 
.language-select .ui.fluid.dropdown {
min-width: 200px !important;

}

.language-select .ui.selection.dropdown {
    min-height: 0 !important;
    padding: 8px 7px !important;
    margin-top: 3px !important;
}

.carousel-text {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 120% !important;
    color: #2B8FFF !important;
    margin-bottom: 20px !important;
}

.carousel_view{
    min-width: 94%;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 26%);
    border-radius: 50px 50px 50px 0px;
    margin: 35px ;
    min-height: 410px;
    background-color: #fff;
    padding: 30px;
    position: relative;
    left:05%
}

.carousel-text{
    padding: 0 10px;
}   
.carousel_item p {
    font-size: 14px;
    width: 100%;
    color: #00406B;
}
.carousel_footer {
    display: flex;
    font-weight: 800 !important;
    font-size: 12px;
    align-items: center;
    line-height: 140%;
    position: absolute;
    bottom: 0.3;
    max-width: 80%;
    color: #00406b;
}
.testimonial-icon{
    /* margin-bottom: 45px; */
    text-align: center;
    font-size: 50px;
}
.testimonial_container{
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 45px 0;
    padding: 25px 0;
    background: #2382eff2;
}
.Carousel-content{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.Carousel-sl{
    width: 65%;
}

.icon-a{
color: #FECD51;
}
.carousel_star {
    margin-bottom: 10px;
}

.contact_form{
         position: relative;
        top: 0;
        left: 90%;
        transform: translate(-50%, -50%);
        min-width: 550px;
        /* max-width: 500px; */
        /* margin-right: auto;
        margin-left: auto; */
        background-color: #fff;
        box-shadow: 1px 0 25px 0 rgb(173 128 35 / 25%);
        z-index: 1000;
        max-height: 500px;
        overflow-y: scroll;
        padding: 20px 30px;
    
}

.contact_form::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  .contact_form::-webkit-scrollbar-track {
    background: rgb(236, 236, 231);        /* color of the tracking area */
  }
  
  .contact_form::-webkit-scrollbar-thumb {
    background-color: rgb(139, 138, 134);    /* color of the scroll thumb */
    /* border-radius: 20px;       roundness of the scroll thumb */
    border: 3px solid rgb(221, 221, 213);  /* creates padding around scroll thumb */
  }
.contact_form.form-control{
    max-width: 500px;
}
.contact_Subtitle{
    margin-bottom: 16px;
    color: #191919;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
}
.contact_header {
    margin-bottom: 16px;
    padding-right: 9px;
    padding-left: 0;
    font-family: 'Heldane display',sans-serif;
    color: #191919;
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
}

.carousel_app{
    display: flex;
    flex-direction: row;
 
    justify-content: space-around;
}
.Carousel-content img{
    width: 160px;
    height: 50px;
    cursor: pointer;
}
.Carousel-content h3 {
    color: #fff;
    font-size: 40px;
}
.Carousel-content p{
    color:black;
    font-size: 15px;
}
